@use "global" as *;


.p-cases-item.-back-gray {
    background-color: rgba($color: $black, $alpha: 0.08);
    padding-top: rem(10);
    padding-bottom: rem(40);

    @include mq("md") {
        padding-top: rem(70);
        padding-bottom: rem(70);
    }
}

.p-cases-item__title-box {
    @include mq("md") {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row-reverse;
    }
}

.p-cases-item__number {
    font-size: rem(40);
    font-weight: $bold;
    font-family: $second-font-family;
    line-height: 1.2;
    color: rgba($color: $black, $alpha: 0.65);
    text-align: right;

    @include mq("md") {
        font-size: rem(70);
    }
}

.p-cases-item__title {
    font-size: rem(20);
    font-weight: $bold;
    background-color: $black;
    color: $white;
    line-height: 1.5;
    padding: rem(5) rem(20) rem(5) rem(25);
    position: relative;
    margin-top: rem(10);
    max-width: fit-content;

    @include mq("md") {
        font-size: rem(27);
        padding: rem(10) rem(30) rem(10) rem(35);
        margin-top: initial;
        // max-width: unset;
    }
}

.p-cases-item__title::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: rem(10);
    background-color: $gold;

    @include mq("md") {
        width: rem(15);
    }
}

.p-cases-item__content {
    margin-top: rem(20);
    width: 78%;
    margin-left: auto;
    margin-right: auto;
    min-width: rem(295);
    background-color: $white;

    @include mq("md") {
        display: flex;
        width: 51.9%;
        margin-top: rem(27);
        min-width: rem(710);
    }

}

.p-cases-item__img {
    @include mq("md") {
        max-width: rem(300);
    }
}

.p-cases-item__img img {
    aspect-ratio: 295/187;
    object-fit: cover;

    @include mq("md") {
        aspect-ratio: 300/200;
    }
}

.p-cases-item__detail {
    border: solid 1px $black;
    padding: rem(20) rem(10);

    @include mq("md") {
        width: 100%;
        padding: rem(40) rem(10) rem(40) rem(70);
    }
}

.p-cases-item__detail.-md {
    padding: rem(20) rem(34);

    @include mq("md") {
        width: 100%;
        padding: rem(40) rem(10) rem(40) rem(70);
    }
}

.p-cases-item__detail.-lg {
    padding: rem(20) rem(52);

    @include mq("md") {
        width: 100%;
        padding: rem(40) rem(10) rem(40) rem(70);
    }
}

.p-cases-item__detail-wrap {
    display: flex;
    column-gap: rem(10);
    margin-top: rem(15);

    @include mq("md") {
        column-gap: rem(20);
    }
}

.p-cases-item__detail-wrap:first-of-type {
    margin-top: initial;
}

.p-cases-item__detail-title {
    width: 100%;
    max-width: rem(72);
    font-size: rem(14);
    font-weight: $medium;
    padding: rem(3);
    background-color: rgba($color: $black, $alpha: 0.1);
    text-align: center;

}

.p-cases-item__detail-text {
    font-size: rem(16);
    line-height: 1.5;
}

.p-cases-item__works {
    margin-top: rem(20);

    @include mq("md") {
        margin-top: rem(50);
        display: flex;
        justify-content: space-between;
        column-gap: rem(40);
        align-items: center;
    }
}

.p-cases-item__before {
    padding: rem(15);
    border-radius: 13px;
    background-color: $gray;
    @include mq("md") {
        width: 45%;
        padding: rem(15) rem(36) rem(20);
        min-height: rem(200);
    }
}

.p-cases-item__sub-title {
    background-color: $white;
    padding: rem(5);
    border-radius: 17px;
    max-width: rem(152);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: rem(16);
    font-weight: $medium;
    line-height: 1.5;
    position: relative;
    @include mq("md") {}
}
.p-cases-item__sub-title.-before::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: rem(27);
     background-image: url(../src/images/page-cases/icon-before.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        aspect-ratio: 15/15;
        width: rem(10);
}
.p-cases-item__sub-title.-after::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: rem(27);
     background-image: url(../src/images/page-cases/icon-after.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        aspect-ratio: 15/15;
        width: rem(15);

}

.p-cases-item__sub-title.-gold {
    color: $gold;
}

.p-cases-item__text {
    margin-top: rem(10);
    font-size: rem(16);
    line-height: 1.5;
}

.p-cases-item__text.-white {
    color: $white;
}

.p-cases-item__text span {
    font-size: rem(20);
    font-weight: $medium;
}

.p-cases-item__text span.-bold {
    font-size: rem(16);
    font-weight: $bold;
}

.p-cases-item__after {
    padding: rem(15);
    border-radius: 13px;
    background-color: $gold;
    @include mq("md") {
        width: 45%;
        padding: rem(15) rem(36) rem(20);
        min-height: rem(226);
    }
}



.p-cases-item__arrow {
    margin-top: rem(15);
    margin-bottom: rem(15);
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-top: 20px solid $black;
    border-bottom: 0;
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {
        margin: initial;
        width: 0;
        height: 0;
        border-style: solid;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 20px solid #555555;
        border-right: 0;
    }
}