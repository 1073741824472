@use "global" as *;

.p-service {
    margin-top: rem(50);
    margin-bottom: rem(50);

    @include mq("md") {
        margin-top: rem(110);
        margin-bottom: rem(110);
    }
}

.p-service__inner {
    position: relative;
}

.p-service__inner::before {
    content: "Service";
    position: absolute;
    top: rem(-40);
    left: rem(16);
    font-style: italic;
    font-family: $second-font-family;
    line-height: 1.51;
    font-weight: $medium;
    color: rgba($color: $black, $alpha: .1);
    font-size: rem(55);

    @include mq("md") {
        top: rem(-80);
        left: rem(-25);
        font-size: rem(144);
    }
}


.p-service__item {
    margin-top: rem(30);

    @include mq("md") {
        margin-top: rem(50);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: rem(86);
    }
}

.p-service__item--reverse {
    @include mq("md") {
        flex-direction: row-reverse;
    }
}

.p-service__img {
    @include mq("md") {
        width: 51.8%;
    }
}

.p-service__img.-page {
    @include mq("md") {
        width: 44.5%;
    }
}

.p-service__img img {
    aspect-ratio: 343/189;
    object-fit: cover;

    @include mq("md") {
        aspect-ratio: 570/240;
    }
}

.p-service__img.-page img {
    aspect-ratio: 343/203;

    @include mq("md") {
        aspect-ratio: 490/290;
    }
}

.p-service__text-box {
    margin-top: rem(10);

    @include mq("md") {
        width: 40%;
    }
}

.p-service__text-box.-page {
    margin-top: rem(15);

    @include mq("md") {
        margin-top: initial;
        width: 50.9%;
    }
}

.p-service__title-box {
    display: flex;
    align-items: center;
    column-gap: rem(15);

    @include mq("md") {
        column-gap: rem(20);
    }
}

.p-service__title-box.u-desktop {
    display: none;

    @include mq("md") {
        display: flex;
    }
}

.p-service__title-box.u-mobile {
    @include mq("md") {
        display: none;
    }
}

.p-service__number {
    font-family: $second-font-family;
    font-size: rem(40);
    font-weight: $semibold;
    line-height: 1.2;
    color: rgba($color: $gold, $alpha: .5);

    @include mq("md") {
        font-size: rem(50);
    }
}

.p-service__title {
    font-size: rem(20);
    font-weight: $bold;
    line-height: 1.5;

    @include mq("md") {
        font-size: rem(27);
    }
}

.p-service__text {
    font-size: rem(16);
    line-height: 1.5;

    @include mq("md") {
        margin-top: rem(10);
    }
}

.p-service__text.-top {
    margin-top: rem(20);
    @include mq("md") {
     margin-top: rem(30);   
    }
}

.p-service__text.-page:not(:first-of-type) {
    margin-top: rem(10);
}

.p-service__text span {
    background: linear-gradient(transparent 75%, rgba($color: $gold, $alpha: .8) 50%);
}
.p-service__text span.-lg {
    font-size: rem(20);
    font-weight: $bold;
    color: $gold;
    background: none;
}


.p-service__btn {
    margin-top: rem(30);

    @include mq("md") {
        margin-top: rem(50);
    }
}


// サービスページのCSS

.p-service__catch {
    padding: rem(3) rem(15);
    background-color: $gold;
    color: $white;
    font-size: rem(18);
    font-weight: $bold;
    text-align: center;
    margin-top: rem(23);
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {
        padding: rem(3) rem(20);
        margin-top: rem(33);
    }
}


.p-service__list {
    padding-left: rem(17);
    position: relative;
    font-size: rem(16);

    @include mq("md") {
        text-align: center;
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

.p-service__list::before {
    content: "";
    position: absolute;
    top: rem(8);
    left: 0;
    background-image: url(../src/images/page-service/check-icon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    aspect-ratio: 12/12;
    width: rem(12);

    @include mq("md") {}
}

.p-service__list:not(:first-of-type) {
    margin-top: rem(5);

    @include mq("md") {
        margin-top: rem(15);
    }
}

.p-service__list span {
    background: linear-gradient(transparent 75%, rgba($color: $black, $alpha: .17) 50%);
}