@use "global" as *;

.p-blog {
    margin-top: rem(50);
    margin-bottom: rem(50);

    @include mq("md") {
        margin-top: rem(110);
        margin-bottom: rem(110);
    }
}

.p-blog__inner {
    position: relative;
}

.p-blog__inner::before {
    content: "Blog";
    position: absolute;
    top: rem(-40);
    right: rem(16);
    font-style: italic;
    font-family: $second-font-family;
    line-height: 1.51;
    font-weight: $medium;
    color: rgba($color: $black, $alpha: .1);
    font-size: rem(55);

    @include mq("md") {
        top: rem(-80);
        right: rem(-0);
        font-size: rem(144);
    }
}

.p-blog__splide {
    margin-top: rem(30);
    padding-left: rem(6);
    padding-right: rem(6);

    @include mq("md") {
        margin-top: rem(50);
    }
}


.p-blog__btn {
    margin-top: rem(30);

    @include mq("md") {
        margin-top: rem(50);
    }
}


/*============ splide ============*/
.splide__arrow {
    background: initial !important;
    width: rem(35) !important;

    @include mq("md") {
        width: rem(40) !important;
    }
}

.splide__arrow--next {
    right: 0 !important;

    @include mq("md") {
        right: rem(-25) !important;
    }
}

.splide__arrow--prev {
    left: 0 !important;

    @include mq("md") {
        left: rem(-25) !important;
    }
}