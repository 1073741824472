@use "global" as *;

.c-section-sub-title {
    text-align: center;
    font-size: rem(12);
    margin-top: rem(10);
    font-weight: $medium;

    @include mq("md") {
        margin-top: rem(15);
        font-size: rem(14);
    }
}
.c-section-sub-title span {
    font-size: rem(16);
    @include mq("md") {
     font-size: rem(20);   
    }
}
.c-section-sub-title.-white {
    color: $white;
}