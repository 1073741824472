@use "global" as *;


.p-blog-item {
display: flex;
flex-direction: column;
}

.p-blog-item__img {

}
.p-blog-item__img img {
    aspect-ratio: 330/200;
    object-fit: cover;

}

.p-blog-item__meta {
margin-top: rem(10);
display: flex;
justify-content: space-between;
flex-wrap: wrap;
}

.p-blog-item__date {
    line-height: 1.43;
    font-size: rem(14);
}

.p-blog-item__category {
font-size: rem(14);
color: $white;
padding: rem(3) rem(15);
background-color: $black;
line-height: 1.43;
}

.p-blog-item__title {
    font-size: rem(20);
    font-weight: $medium;
    margin-top: rem(10);
    @include mq("md") {
        
    }
}

.p-blog-item__text {
    font-size: rem(16);
    margin-top: rem(10);
    margin-bottom: rem(20);
    @include mq("md") {
        margin-bottom: rem(10);
    }
}

.p-blog-item__btn {
margin-top: auto;
}



