@use "global" as *;

.c-banner {
    margin-top: rem(50);
    margin-bottom: rem(50);

    @include mq("md") {
        margin-top: rem(110);
        margin-bottom: rem(110);
    }
}

.c-banner__inner {}


.c-banner__wrap {
    @include mq("md") {
        display: flex;
        column-gap: rem(50);
        justify-content: center;
        
    }
}

.c-banner__img {
    margin-top: rem(20);
    display: block;

    @include mq("md") {
        margin-top: initial;
    }
}

.c-banner__img:first-of-type {
    margin-top: initial;
}

.c-banner__img img {
    aspect-ratio: 348/123;
    object-fit: cover;
}