@use "global" as *;




.p-form__title {
    font-size: rem(20);
    font-weight: $medium;
    line-height: 1.43;
    @include mq("md") {
        
    }
}




.p-form__text {
    font-size: rem(14);
    line-height: 1.43;
    margin-top: rem(10);
    @include mq("md") {
     margin-top: rem(20);   
    }
}

.p-form__title {
    font-size: rem(16);
    font-weight: $medium;
    line-height: 1.43;
    margin-top: rem(20);
    display: block;
    @include mq("md") {
        margin-top: rem(30);
        font-size: rem(20);
    }
}
.p-form__title:first-of-type {
    @include mq("md") {
        margin-top: rem(40);
    }
}
.p-form .required {
    background-color: $gold;
    color: $white;
    padding: rem(2) rem(10);
    font-size: rem(12);
    font-weight: $medium;
    display: inline-block;
    margin-left: rem(20);
    @include mq("md") {
        
    }
}
.p-form input,
.p-form textarea {
    background-color: rgba($color: $black, $alpha: 0.03);
    margin-top: rem(10);
    width: 100%;
     padding: rem(10);
}
.p-form .wpcf7-form-control-wrap {
    margin-top: rem(10);
    display: block;
}

.p-form .wpcf7-list-item {
    margin-left: initial;
}
.p-form .wpcf7-list-item input[type=checkbox], 
.p-form .wpcf7-list-item input[type=radio] {
    border: solid 1px $black;
    background-color: $white;
    width: rem(10);
    height: rem(10);
     padding: rem(0);
     margin: 0;
     margin-right: rem(5);
}

.p-form .wpcf7-list-item {
    margin-right: rem(20);
    margin-left: initial;
}
