@use "global" as *;

/* ページトップボタン */


.c-pagetop {
  cursor: pointer;
  position: fixed;
  right: rem(16);
  bottom: rem(30);
  transition: .3s;
  max-width: rem(30);
  opacity: 0;

  @include mq("md") {
    right: rem(25);
    max-width: rem(50);
  }
}

.c-pagetop img {
  aspect-ratio: 30/30;
  object-fit: cover;
}