@use "global" as *;


.header {
    background: $white;
    height: rem(60);
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;

    @include mq("md") {
        height: rem(80);
    }
}

.header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: inherit;

    @include mq("md") {
        align-items: center;
    }
}

.header__logo {
    height: inherit;
    width: 100%;
    max-width: rem(141);
    width: 100%;
    z-index: 9999;
    // padding-left: rem(16);
    height: inherit;
    display: flex;
    align-items: center;

    @include mq("md") {
        max-width: rem(240);
        // padding-left: rem(30);
    }
}

.header__logo img {
    aspect-ratio: 141 / 26;
    width: 100%;

    @include mq("md") {}
}

.header__logo-img {

    margin-top: rem(6);

    @include mq("md") {
        margin-top: initial;
    }
}

.header__hamburger {
    position: relative;
    z-index: 10000;
    @include mq("md") {
        display: none;
    }
}

.header__nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    @include mq("md") {
        display: block;
        position: static;

    }
}

// .header.change-color {
//     background: rgba(17, 17, 17, 1);
//     transition: 0.3s;
// }