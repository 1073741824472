@use "global" as *;


.p-message {
    padding-top: rem(40);
    padding-bottom: rem(40);
    background-image: url(../src/images/top/message_back_sp.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    line-height: 1.5;
    @include mq("md") {
        padding-top: rem(70);
        padding-bottom: rem(70);
        background-image: url(../src/images/top/message_back.jpg);
    }
}

.p-message_inner {}



.p-message__title {}



.p-message__detail {
    margin-top: rem(30);
    max-width: rem(750);
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {
        margin-top: rem(50);
    }
}

.p-message__text {
    text-align: center;
    font-size: rem(16);
    font-weight: $medium;
    word-break: keep-all;
    overflow-wrap: anywhere;
    line-height: 1.5;
    @include mq("md") {
        
    }
}

.p-message__text:not(:first-child) {
    margin-top: rem(28);
    @include mq("md") {}
}

.p-message__btn {
    margin-top: rem(30);

    @include mq("md") {
        margin-top: rem(50);
    }
}