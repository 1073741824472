@use "global" as *;

.p-blog-list {}

.p-blog-list__item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: rem(25);
    margin-top: rem(30);
    @include mq("md") {
        column-gap: rem(60);
        margin-top: rem(50);
    }
}
.p-blog-list__item:first-of-type {
margin-top: initial;
}
.p-blog-list__img {
    width: 32%;
    @include mq("md") {
        width: 28%;
        max-width: rem(200);
    }
}
.p-blog-list__img img {
aspect-ratio: 200/134;
object-fit: cover;
}
.p-blog-list__content {
    width: 60%;

    @include mq("md") {
        width: 65.7%;
    }
}

.p-blog-list__meta {}

.p-blog-list__date {
    display: block;
    margin-top: rem(3);
    line-height: 1.43;
    font-size: rem(14);
    @include mq("md") {
     margin-top: rem(10);   
    }
}

.p-blog-list__category {
    font-size: rem(12);
    color: $white;
    padding: rem(3) rem(5);
    background-color: $black;
    @include mq("md") {
        font-size: rem(14);
        padding: rem(3) rem(15);
    }
}

.p-blog-list__title {
    margin-top: rem(3);
    font-size: rem(16);
    font-weight: $medium;
    line-height: 1.5;
    @include mq("md") {
        margin-top: rem(10);   
        font-size: rem(20);
       }
}

.p-blog-list__text {
    @include mq("md") {
        margin-top: rem(10);   
        font-size: rem(16);
        line-height: 1.5;
       }
}