@use "global" as *;


.p-page-service__body {
    margin-top: rem(30);
    background-color: rgba($color: $black, $alpha: .03);
    padding-top: rem(40);
    padding-bottom: rem(40);

    @include mq("md") {
        margin-top: rem(50);
        padding-top: rem(70);
        padding-bottom: rem(70);
    }
}

.p-page-service__text {
    margin-top: rem(28);
    line-height: 1.5;
    font-size: rem(16);

    @include mq("md") {
        text-align: center;
        margin-top: rem(23);
    }
}

.p-page-service__text:first-of-type {
    margin-top: initial;
}

.p-page-service__text .-marker {
    background: linear-gradient(transparent 75%, rgba($color: $gold, $alpha: .8) 50%);
}

.p-page-service__text .-lg {
    font-size: rem(27);
    color: $gold;
    line-height: 1.5;
    font-weight: $bold;

}

.p-page-service__img {
    margin-top: rem(20);
    max-width: rem(403);
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {
        margin-top: rem(30);
        max-width: rem(564);
    }
}

.p-page-service__img img {
    aspect-ratio: 323/130;
    object-fit: cover;

    @include mq("md") {
        aspect-ratio: 564/184;
    }
}

.p-page-service__item {
    margin-top: rem(50);
    @include mq("md") {
        margin-top: rem(110);
    }
}