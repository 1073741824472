@use "global" as *;

.p-cases-content {
    background-image: url(../src/images/page-cases/cases_base.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: rem(30);
    padding-top: rem(40);
    padding-bottom: rem(45);
    margin-bottom: rem(183);
    position: relative;

    @include mq("md") {
        margin-top: rem(50);
        padding-top: rem(70);
        padding-bottom: rem(54);
        margin-bottom: rem(170);
    }
}

.p-cases-content__inner {
    @include mq("md") {
        max-width: rem(959);
        margin-left: auto;
        margin-right: auto;
    }
}

.p-cases-content__item {
    border: solid 1px $gold;
    background-color: $white;
    padding: rem(20) rem(18) rem(20) rem(20);
    margin-top: rem(35);
    box-shadow: rem(5) rem(5) rgba($color: $gold, $alpha: 0.5);

    @include mq("md") {
        margin-top: rem(60);
        padding: rem(25) rem(30);
        max-width: rem(657);
        box-shadow: rem(10) rem(10) rgba($color: $gold, $alpha: 0.5);
    }
}

.p-cases-content__item:first-of-type {
    margin-top: initial;
}

.p-cases-content__item.-right {
    @include mq("md") {
        margin-left: rem(78);
    }
}

.p-cases-content__item.-more-right {
    @include mq("md") {
        margin-right: 0;
        margin-left: auto;
    }
}

.p-cases-content__title {
    font-size: rem(20);
    font-weight: $medium;
    line-height: 1.5;

    @include mq("md") {
        font-size: rem(27);
    }
}

.p-cases-content__text {
    margin-top: rem(15);
    font-size: rem(16);
    line-height: 1.5;

}


.p-cases-content__img {
    position: absolute;
    bottom: rem(-133);
    left: 50%;
    transform: translateX(-50%);
    width: 62%;
    min-width: rem(235);
    max-width: rem(235);

    @include mq("md") {
        left: 0;
        transform: translateX(0);
        bottom: rem(-70);
        max-width: rem(417);
        width: 30%;
    }
    @include mq("lg") {
        bottom: rem(-60);
        max-width: rem(417);
    }
}

.p-cases-content__img img {
    aspect-ratio: 235/143;
    object-fit: cover;
    @include mq("md") {
        aspect-ratio: 418/255;
    }
}