@use "sass:math";

////////////////////
// インナー幅設定
////////////////////
// コンテンツのインナー幅（最も共通の幅が多い部分）
$inner: 1100px;
// ※キャンパス幅ではなくインナーの幅を指す

// 余白
$padding-pc: 25px;
$padding-sp: 16px;
// ※PC時：デザインカンプの余白の幅ではない。PC時は通常25pxで大丈夫！


////////////////////
// フォント設定
////////////////////

$base-font-family: "Noto Sans JP", serif;
$second-font-family: "Rubik", sans-serif;
// ※検証ツールでしっかり反映されているか確認する

body {
  font-family: $base-font-family;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
}

// フォントウェイト
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;


////////////////////
// 色の指定
////////////////////

//色指定（共通性のあるものは出来る限り指定すると良い）
$black: #3A3A3A;
$white: #fff;
$gold: #B19E61;
$gray: #DBDBDB;
$gray-back: #3a3a3a;
$blue: #3271b8;

////////////////////
// メディアクエリとブレークポイントの設定
////////////////////

//ブレイクポイント
$breakpoints: (
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1440,
);


//メディアクエリー
$mediaquerys: (
  sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
);

// @include mq(){}で書くとブレークポイントが反映される（初期値はmd）
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}

// remへの計算式（16pxを基準としている。10pxを基準とする場合は16を10に変更する）
@function rem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}


////////////////////
// 非表示設定
////////////////////

//768px以上を表示
.u-desktop {
  display: none;

  @include mq("md") {
    display: block;
  }
}

//モバイルのみ表示
.u-mobile {
  @include mq("md") {
    display: none;
  }
}