@use "global" as *;

.c-btn {
    font-size: rem(16);
    font-weight: $medium;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $gold;
    color: $white;
    line-height: 1.5;
    // padding: 13px 35px 13px 78px;
    padding: rem(13);
    max-width: rem(250);
    margin-right: auto;
    margin-left: auto;
    position: relative;
    display: block;
    @include mq("md") {
        // padding: 13px 35px 13px 78px;
    }

}
.c-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: rem(35);
	box-sizing: border-box;
	width: 6px;
	height: 6px;
	border: 6px solid transparent;
	border-left: 6px solid $white;
    transform: translateY(-50%);
}

.p-recruit-form .c-btn,
.p-form .c-btn{
 
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $gold;
     padding: rem(0);
    line-height: 1.5;
    // padding: 13px 35px 13px 78px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    display: block;
    margin-top: rem(30);
    @include mq("md") {
        margin-top: rem(50);
    }
}
.p-recruit-form .c-btn input,
.p-form .c-btn input {
    max-width: rem(250);
    padding: rem(13);
    font-size: rem(16);
    font-weight: $medium;
    text-align: center;
    color: $white;
    margin: 0;
    @include mq("md") {
        
    }
}
.p-recruit-form .c-btn .wpcf7-spinner,
.p-form .c-btn .wpcf7-spinner {
display: none;
}
// .p-recruit-form .c-btn::after,
// .p-form .c-btn::after {
//     content: "";
//     position: absolute;
//     top: 50%;
//     right: rem(35);
// 	box-sizing: border-box;
// 	width: 6px;
// 	height: 6px;
// 	border: 6px solid transparent;
// 	border-left: 6px solid $white;
//     transform: translateY(-50%);
//     z-index: 10;
// }