@use "global" as *;

.p-page-recruit__wrap {
    margin-top: rem(30);

    @include mq("md") {
        display: flex;
        justify-content: center;
        column-gap: rem(60);
        margin-top: rem(50);
    }
}

.p-page-recruit__wrap.-reverse {
    @include mq("md") {
        flex-direction: row-reverse;
    }
}

.p-page-recruit__img {
    @include mq("md") {
        width: 50.9%;
    }
}

.p-page-recruit__img img {
    aspect-ratio: 343/193;
    object-fit: cover;
}

.p-page-recruit__box {
    margin-top: rem(15);

    @include mq("md") {
        margin-top: initial;
        width: 43.6%;
    }
}

.p-page-recruit__title {
    line-height: 1.5;
    font-size: rem(20);
    font-weight: $bold;

    @include mq("md") {
        font-size: rem(27);
    }
}

.p-page-recruit__text {
    line-height: 1.5;
    font-size: rem(16);
    margin-top: rem(15);

    @include mq("md") {
        margin-top: rem(30);
    }
}

.p-page-recruit__form {
    margin-top: rem(50);
    @include mq("md") {
        margin-top: rem(110); 
        max-width: rem(700);
        margin-left: auto;
        margin-right: auto;
    }
}

