@use "global" as *;

.p-page-cases {

}

.p-page-cases__content {
    
}

.p-page-cases__item.-top {
    margin-top: rem(50);
    margin-bottom: rem(50);
    @include mq("md") {
        margin-top: rem(70);
        margin-bottom: rem(70);   
    }
}