@use "global" as *;

.c-section-title {
    font-family: $second-font-family;
    font-weight: $semibold;
    text-align: center;
font-size: rem(24);
line-height: 1;
    @include mq("md") {
        font-size: rem(40);
    }
}

.c-section-title.-white {
    color: $white;
}