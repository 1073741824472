@use "global" as *;

.p-cases {
    padding-top: rem(40);
    padding-bottom: rem(50);
    position: relative;

    @include mq("md") {
        padding-top: rem(70);
        padding-bottom: rem(70);
        position: relative;
    }
}

.p-cases::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    max-width: rem(600);
    background-image: url(../src/images/top/cases_back_sp.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    aspect-ratio: 359/431;
    z-index: -1;

    @include mq("md") {
        background-image: url(../src/images/top/cases_back.jpg);
        width: 93%;
        aspect-ratio: 1276/595;
        max-width: rem(1350);
    }
}

.p-cases__wrap {
    @include mq("md") {
        display: flex;
        column-gap: rem(45);
    }
}

.p-cases__text-box {
    @include mq("md") {
     width: 50%;   
    }
}

.p-cases__title {
    font-size: rem(24);
    font-weight: $bold;
    color: $gold;
    line-height: 1.5;

    @include mq("md") {
        font-size: rem(36);
    }
}

.p-cases__content {
    font-size: rem(24);
    font-weight: $bold;

    // line-height: 1.5;
    @include mq("md") {
        font-size: rem(27);
    }
}

.p-cases__content span {
    font-size: rem(40);
    font-weight: $semibold;
    font-family: $second-font-family;
    color: $gold;
    display: inline-block;
    margin-right: rem(5);
    margin-left: rem(5);
    line-height: 1.2;

    @include mq("md") {}
}

.p-cases__content span.-sm {
    font-size: rem(16);
    font-weight: bold;
    line-height: 1.4;
    color: $black;

    @include mq("md") {
        font-size: rem(20);
    }
}

.p-cases__text:first-of-type {
    margin-top: rem(20);

    @include mq("md") {
        margin-top: rem(30);
    }
}

.p-cases__text {
    margin-top: rem(10);
    font-size: rem(16);
    line-height: 1.5;

    @include mq("md") {
        margin-top: rem(25);
    }
}

.p-cases__img {
    margin-top: rem(10);

    @include mq("md") {
        margin-top: rem(70);
        width: 47%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    }
}

.p-cases__img img {}