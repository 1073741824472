@use "global" as *;
.p-page-gallery {
    margin-top: rem(50);
    @include mq("md") {
        margin-top: rem(110);
    }
}
.p-page-gallery__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: rem(850);
    margin-left: auto;
    margin-right: auto;
    column-gap: rem(22);
    row-gap: rem(20);
    margin-top: rem(30);

    @include mq("md") {
        column-gap: rem(50);
        row-gap: rem(50);
        margin-top: rem(50);
    }
}

.p-page-gallery__img {
    width: 28.7%;
}

.p-page-gallery__img img {
    aspect-ratio: 250/250;
    object-fit: cover;
}