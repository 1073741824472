@use "global" as *;

//============================== 下からふわっと出現
.js-fadeup {
    opacity: 0;
    visibility: hidden;
    transition: all 1s;
    transform: translateY(150px);
  }
  .is-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }


//============================== テキストがじわっと出現
/* 画面上で隠すために初期状態で透明にする */
.fade-in-text {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

/* スクロールされたときに opacity を変更して表示する */
.fade-in-text.fade-in {
    opacity: 1;
}

//============================== 左から右に出現
.js-left-right {
    /* アニメーションのプロパティを指定 */
    -webkit-transform: translateZ(0);
    animation-name: example2;
    animation-duration: 9s;
    animation-timing-function: ease-in;
    position: relative;
    display: block;
    opacity: 1;
  }
  
  @keyframes example2 {
    from {
      opacity: 0;
      left: -100px;
    }
    to {
      opacity: 1;
      left: 0;
    }
  }

  //============================== 左から右に出現

  .animated-slide-in {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
.slide-in {
    animation: slideIn 0.5s forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }



    //==============================　タイトルアニメーション
    // .fade-in-title {
    //     opacity: 0;
    //     transition: opacity 0.5s ease-in-out;
    //     position: relative;
    //     bottom: -20px; /* 下からスタート */
    //   }
      
    //   .fade-in-title.fade-in2 {
    //     opacity: 1;
    //     bottom: 0; /* 上に移動 */
    //   }