@use "global" as *;

.c-more-btn {
    font-size: rem(14);
    max-width: rem(150);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: rem(6);
    border-radius: 16px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px $black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: rem(20);

    @include mq("md") {}
}

.c-more-btn-arrow {
    display: inline-block;
    vertical-align: middle;
    color: $black;
    line-height: 1;
    position: relative;
    width: rem(8);
    height: rem(8);
    transform: translateX(-25%) rotate(45deg);

    @include mq("md") {}
}

.c-more-btn-arrow::before,
.c-more-btn-arrow::after {
    content: "";
    position: absolute;
    background: currentColor;
    border-radius: 0.1em;
}

.c-more-btn-arrow::before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.1em;
}

.c-more-btn-arrow::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.1em;
}