@charset "UTF-8";
/* リキッドレイアウト対応 */
body {
  font-family: "Noto Sans JP", serif;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  letter-spacing: normal;
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}

html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.4545454545vw;
  }
}
@media (min-width: 1100px) {
  html {
    font-size: 16px;
  }
}

/* pcの電話番号発信対応 */
@media screen and (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  a:hover {
    opacity: 0.8;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
  margin-top: initial;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

input {
  border: none;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

html.fixed {
  position: fixed;
}

.breadcrumbs {
  margin-top: 0.3125rem;
}
@media screen and (min-width: 768px) {
  .breadcrumbs {
    margin-top: 0.625rem;
  }
}

.breadcrumb_inner span {
  line-height: 1.43;
  font-size: 0.875rem;
  color: #3A3A3A;
  font-weight: 500;
  display: inline-block;
}

.c-banner-recruit {
  background-image: url(../src/images/top/recruit_base_sp.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .c-banner-recruit {
    background-image: url(../src/images/top/recruit_base.jpg);
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
}

@media screen and (min-width: 768px) {
  .c-banner-recruit__wrap {
    display: flex;
    column-gap: 3.4375rem;
  }
}

@media screen and (min-width: 768px) {
  .c-banner-recruit__item {
    width: 30%;
  }
}

.c-banner-recruit__icon {
  aspect-ratio: 100/100;
  object-fit: cover;
  max-width: 6.25rem;
}
.c-banner-recruit__text {
  margin-top: 1.25rem;
  line-height: 1.5;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .c-banner-recruit__text {
    margin-top: 1.875rem;
  }
}

.c-banner-recruit__img {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .c-banner-recruit__img {
    width: 65%;
    margin-top: initial;
  }
}

.c-banner-recruit__img img {
  aspect-ratio: 343/124;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .c-banner-recruit__img img {
    aspect-ratio: 715/258;
  }
}

.c-banner {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .c-banner {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
}

@media screen and (min-width: 768px) {
  .c-banner__wrap {
    display: flex;
    column-gap: 3.125rem;
    justify-content: center;
  }
}

.c-banner__img {
  margin-top: 1.25rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .c-banner__img {
    margin-top: initial;
  }
}

.c-banner__img:first-of-type {
  margin-top: initial;
}

.c-banner__img img {
  aspect-ratio: 348/123;
  object-fit: cover;
}

.c-btn {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #B19E61;
  color: #fff;
  line-height: 1.5;
  padding: 0.8125rem;
  max-width: 15.625rem;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: block;
}
.c-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2.1875rem;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 6px solid transparent;
  border-left: 6px solid #fff;
  transform: translateY(-50%);
}

.p-recruit-form .c-btn,
.p-form .c-btn {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #B19E61;
  padding: 0rem;
  line-height: 1.5;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: block;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-recruit-form .c-btn,
  .p-form .c-btn {
    margin-top: 3.125rem;
  }
}

.p-recruit-form .c-btn input,
.p-form .c-btn input {
  max-width: 15.625rem;
  padding: 0.8125rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin: 0;
}
.p-recruit-form .c-btn .wpcf7-spinner,
.p-form .c-btn .wpcf7-spinner {
  display: none;
}

.js-fadeup {
  opacity: 0;
  visibility: hidden;
  transition: all 1s;
  transform: translateY(150px);
}

.is-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* 画面上で隠すために初期状態で透明にする */
.fade-in-text {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

/* スクロールされたときに opacity を変更して表示する */
.fade-in-text.fade-in {
  opacity: 1;
}

.js-left-right {
  /* アニメーションのプロパティを指定 */
  -webkit-transform: translateZ(0);
  animation-name: example2;
  animation-duration: 9s;
  animation-timing-function: ease-in;
  position: relative;
  display: block;
  opacity: 1;
}

@keyframes example2 {
  from {
    opacity: 0;
    left: -100px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}
.animated-slide-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.c-more-btn {
  font-size: 0.875rem;
  max-width: 9.375rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 0.375rem;
  border-radius: 16px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #3A3A3A;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1.25rem;
}
.c-more-btn-arrow {
  display: inline-block;
  vertical-align: middle;
  color: #3A3A3A;
  line-height: 1;
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  transform: translateX(-25%) rotate(45deg);
}
.c-more-btn-arrow::before,
.c-more-btn-arrow::after {
  content: "";
  position: absolute;
  background: currentColor;
  border-radius: 0.1em;
}

.c-more-btn-arrow::before {
  top: 0;
  left: 0;
  right: 0;
  height: 0.1em;
}

.c-more-btn-arrow::after {
  top: 0;
  right: 0;
  bottom: 0;
  width: 0.1em;
}

.c-section-sub-title {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 0.625rem;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .c-section-sub-title {
    margin-top: 0.9375rem;
    font-size: 0.875rem;
  }
}

.c-section-sub-title span {
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .c-section-sub-title span {
    font-size: 1.25rem;
  }
}

.c-section-sub-title.-white {
  color: #fff;
}

.c-section-title {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .c-section-title {
    font-size: 2.5rem;
  }
}

.c-section-title.-white {
  color: #fff;
}

.inner {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 37.5rem;
}
@media screen and (min-width: 768px) {
  .inner {
    max-width: 1150px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.l-footer {
  background-color: #3A3A3A;
  padding-top: 2.8125rem;
  padding-bottom: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .l-footer {
    padding-top: 4.375rem;
  }
}

@media screen and (min-width: 768px) {
  .l-footer__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 4.375rem;
  }
}

.l-footer__link {
  line-height: 1.5;
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.l-footer__wrap {
  display: flex;
  column-gap: 1.25rem;
  justify-content: center;
  margin-top: 1.875rem;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .l-footer__wrap {
    column-gap: 4.375rem;
    margin-top: initial;
  }
}

.l-footer__wrap:first-of-type {
  margin-top: initial;
}

.l-footer__sns-wrap {
  display: flex;
  justify-content: center;
  column-gap: 5rem;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .l-footer__sns-wrap {
    margin-top: 3.125rem;
  }
}

.l-footer__icon {
  aspect-ratio: 30/30;
  max-width: 1.875rem;
}

.l-footer__access {
  margin-top: 1.875rem;
  font-size: 0.875rem;
  color: #fff;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .l-footer__access {
    margin-top: 3.125rem;
  }
}

.l-footer__copy {
  margin-top: 3.125rem;
  font-size: 0.75rem;
  color: #fff;
  text-align: center;
  display: block;
}
@media screen and (min-width: 768px) {
  .l-footer__copy {
    margin-top: 4.375rem;
  }
}

.hamburger {
  padding: 0.8125rem 1.3125rem;
}

.hamburger span {
  display: block;
  width: 1.625rem;
  height: 0.0625rem;
  background-color: #3A3A3A;
  border-radius: 0.125rem;
  transition: 0.3s;
}

.hamburger span:nth-child(2) {
  margin: 0.625rem 0;
}

.hamburger.open span:nth-child(1) {
  transform: translate(0.1875rem, 0rem) rotate(45deg);
  transform-origin: left;
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translate(0.1875rem, 0.1875rem) rotate(-45deg);
  transform: translate(0.1875rem, -0.20875rem) rotate(-45deg);
  transform-origin: left;
}

.header-nav {
  width: 100%;
  height: 100vh;
}
@media screen and (min-width: 768px) {
  .header-nav {
    background-color: initial;
    margin-top: initial;
    width: initial;
    height: inherit;
    overflow-y: initial;
  }
}

.header-nav__items {
  height: inherit;
  display: flex;
  flex-direction: column;
  margin-top: 8.75rem;
  min-height: 25rem;
}
@media screen and (min-width: 768px) {
  .header-nav__items {
    margin-top: initial;
    min-height: initial;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.header-nav__item {
  position: relative;
  text-align: center;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .header-nav__item {
    height: inherit;
    margin-top: initial;
    white-space: nowrap;
  }
}

.header-nav__item:first-of-type {
  margin-top: initial;
}

.header-nav__item a {
  display: block;
  height: inherit;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .header-nav__item a {
    color: #3A3A3A;
    padding: 1.3125rem 1.5625rem;
  }
}

.header-nav__item a::after,
.header-nav__btn a::after {
  font-family: "Noto Sans JP", sans-serif;
  content: attr(data-title);
  display: block;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .header-nav__item a::after,
  .header-nav__btn a::after {
    color: #3A3A3A;
  }
}

.header-nav__btn a::after {
  color: #fff;
}

.header-nav__btn a:hover::after {
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .header-nav__btn a:hover::after {
    color: #3A3A3A;
  }
}

.header-nav__btn {
  margin-top: 1.875rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .header-nav__btn {
    margin-top: initial;
  }
}

.header-nav__btn a {
  font-size: 1rem;
  color: #fff;
  display: inline-block;
  background-color: #B19E61;
  text-align: center;
  width: 100%;
  max-width: 9.375rem;
  padding: 0.5rem 2.8125rem 0.375rem 2.5rem;
  transition: 0.3s;
  border: 1px solid #B19E61;
  position: relative;
}
@media screen and (min-width: 768px) {
  .header-nav__btn a {
    line-height: 1.5;
    font-size: 1rem;
    height: inherit;
    border: 1px solid #3A3A3A;
    background-color: #3A3A3A;
    max-width: 8.125rem;
    margin-right: 0.3125rem;
    padding: 0.5rem 2.25rem 0.375rem 1.875rem;
  }
}

.header-nav__btn a:hover {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .header-nav__btn a:hover {
    color: #3A3A3A;
    background-color: #fff;
    transition: 0.3s;
  }
}

.header-nav__btn a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5625rem;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 6px solid transparent;
  border-left: 6px solid #fff;
  transform: translateY(-50%);
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .header-nav__btn a::before {
    right: 0.9375rem;
  }
}

.header-nav__btn a:hover::before {
  border-left: 6px solid #B19E61;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .header-nav__btn a:hover::before {
    border-left: 6px solid #3A3A3A;
  }
}

.circle {
  position: fixed;
  z-index: 3;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(58, 58, 58, 0.7);
  transform: scale(0);
  right: -50px;
  top: -50px;
  transition: all 2s;
}

.circle.circleactive {
  transform: scale(50);
}

.header {
  background: #fff;
  height: 3.75rem;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}
@media screen and (min-width: 768px) {
  .header {
    height: 5rem;
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
}
@media screen and (min-width: 768px) {
  .header__inner {
    align-items: center;
  }
}

.header__logo {
  height: inherit;
  width: 100%;
  max-width: 8.8125rem;
  width: 100%;
  z-index: 9999;
  height: inherit;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .header__logo {
    max-width: 15rem;
  }
}

.header__logo img {
  aspect-ratio: 141/26;
  width: 100%;
}
.header__logo-img {
  margin-top: 0.375rem;
}
@media screen and (min-width: 768px) {
  .header__logo-img {
    margin-top: initial;
  }
}

.header__hamburger {
  position: relative;
  z-index: 10000;
}
@media screen and (min-width: 768px) {
  .header__hamburger {
    display: none;
  }
}

.header__nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .header__nav {
    display: block;
    position: static;
  }
}

.l-page-header {
  margin-top: 3.75rem;
}
@media screen and (min-width: 768px) {
  .l-page-header {
    margin-top: 5rem;
  }
}

.l-page-header img {
  aspect-ratio: 1366/470;
}
@media screen and (min-width: 768px) {
  .l-page-header img {
    aspect-ratio: 1366/420;
  }
}

.l-page-top {
  margin-top: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .l-page-top {
    margin-top: 2.5rem;
  }
}

/* ページトップボタン */
.c-pagetop {
  cursor: pointer;
  position: fixed;
  right: 1rem;
  bottom: 1.875rem;
  transition: 0.3s;
  max-width: 1.875rem;
  opacity: 0;
}
@media screen and (min-width: 768px) {
  .c-pagetop {
    right: 1.5625rem;
    max-width: 3.125rem;
  }
}

.c-pagetop img {
  aspect-ratio: 30/30;
  object-fit: cover;
}

.test {
  font-size: 1.25rem;
  background-color: pink;
  color: #fff;
}

.wp-pagenavi {
  clear: both;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 0.625rem;
}

.p-page-blog__pagenavi .wp-pagenavi a,
.p-page-blog__pagenavi .wp-pagenavi span {
  color: #3A3A3A;
  background-color: #fff;
  padding: 0.4375rem 0.9375rem;
  border: solid 1px #3A3A3A;
  text-align: center;
  text-decoration: none;
  display: block;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
}

.wp-pagenavi a:hover {
  color: #fff;
  background-color: #3A3A3A;
  border-color: #3A3A3A;
}

.wp-pagenavi span.current {
  color: #fff;
  background-color: #3A3A3A;
  border-color: #3A3A3A;
  font-weight: bold;
}

.p-page-blog__pagenavi .wp-pagenavi .pages {
  display: none;
}

.p-page-blog__pagenavi .wp-pagenavi .nextpostslink,
.p-page-blog__pagenavi .wp-pagenavi .previouspostslink {
  border: none;
  padding: 0.4375rem 0rem;
}

.p-page-blog__pagenavi .wp-pagenavi .nextpostslink:hover,
.p-page-blog__pagenavi .wp-pagenavi .previouspostslink:hover {
  background-color: initial;
  border-color: initial;
  opacity: 0.7;
  color: #3A3A3A;
}

.p-blog-item {
  display: flex;
  flex-direction: column;
}

.p-blog-item__img img {
  aspect-ratio: 330/200;
  object-fit: cover;
}

.p-blog-item__meta {
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-blog-item__date {
  line-height: 1.43;
  font-size: 0.875rem;
}

.p-blog-item__category {
  font-size: 0.875rem;
  color: #fff;
  padding: 0.1875rem 0.9375rem;
  background-color: #3A3A3A;
  line-height: 1.43;
}

.p-blog-item__title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 0.625rem;
}
.p-blog-item__text {
  font-size: 1rem;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-blog-item__text {
    margin-bottom: 0.625rem;
  }
}

.p-blog-item__btn {
  margin-top: auto;
}

.p-blog-list__item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 1.5625rem;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-blog-list__item {
    column-gap: 3.75rem;
    margin-top: 3.125rem;
  }
}

.p-blog-list__item:first-of-type {
  margin-top: initial;
}

.p-blog-list__img {
  width: 32%;
}
@media screen and (min-width: 768px) {
  .p-blog-list__img {
    width: 28%;
    max-width: 12.5rem;
  }
}

.p-blog-list__img img {
  aspect-ratio: 200/134;
  object-fit: cover;
}

.p-blog-list__content {
  width: 60%;
}
@media screen and (min-width: 768px) {
  .p-blog-list__content {
    width: 65.7%;
  }
}

.p-blog-list__date {
  display: block;
  margin-top: 0.1875rem;
  line-height: 1.43;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .p-blog-list__date {
    margin-top: 0.625rem;
  }
}

.p-blog-list__category {
  font-size: 0.75rem;
  color: #fff;
  padding: 0.1875rem 0.3125rem;
  background-color: #3A3A3A;
}
@media screen and (min-width: 768px) {
  .p-blog-list__category {
    font-size: 0.875rem;
    padding: 0.1875rem 0.9375rem;
  }
}

.p-blog-list__title {
  margin-top: 0.1875rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-blog-list__title {
    margin-top: 0.625rem;
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .p-blog-list__text {
    margin-top: 0.625rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}

.p-blog {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-blog {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
}

.p-blog__inner {
  position: relative;
}

.p-blog__inner::before {
  content: "Blog";
  position: absolute;
  top: -2.5rem;
  right: 1rem;
  font-style: italic;
  font-family: "Rubik", sans-serif;
  line-height: 1.51;
  font-weight: 500;
  color: rgba(58, 58, 58, 0.1);
  font-size: 3.4375rem;
}
@media screen and (min-width: 768px) {
  .p-blog__inner::before {
    top: -5rem;
    right: 0rem;
    font-size: 9rem;
  }
}

.p-blog__splide {
  margin-top: 1.875rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
@media screen and (min-width: 768px) {
  .p-blog__splide {
    margin-top: 3.125rem;
  }
}

.p-blog__btn {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-blog__btn {
    margin-top: 3.125rem;
  }
}

/*============ splide ============*/
.splide__arrow {
  background: initial !important;
  width: 2.1875rem !important;
}
@media screen and (min-width: 768px) {
  .splide__arrow {
    width: 2.5rem !important;
  }
}

.splide__arrow--next {
  right: 0 !important;
}
@media screen and (min-width: 768px) {
  .splide__arrow--next {
    right: -1.5625rem !important;
  }
}

.splide__arrow--prev {
  left: 0 !important;
}
@media screen and (min-width: 768px) {
  .splide__arrow--prev {
    left: -1.5625rem !important;
  }
}

.p-cases-content {
  background-image: url(../src/images/page-cases/cases_base.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 1.875rem;
  padding-top: 2.5rem;
  padding-bottom: 2.8125rem;
  margin-bottom: 11.4375rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-cases-content {
    margin-top: 3.125rem;
    padding-top: 4.375rem;
    padding-bottom: 3.375rem;
    margin-bottom: 10.625rem;
  }
}

@media screen and (min-width: 768px) {
  .p-cases-content__inner {
    max-width: 59.9375rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-cases-content__item {
  border: solid 1px #B19E61;
  background-color: #fff;
  padding: 1.25rem 1.125rem 1.25rem 1.25rem;
  margin-top: 2.1875rem;
  box-shadow: 0.3125rem 0.3125rem rgba(177, 158, 97, 0.5);
}
@media screen and (min-width: 768px) {
  .p-cases-content__item {
    margin-top: 3.75rem;
    padding: 1.5625rem 1.875rem;
    max-width: 41.0625rem;
    box-shadow: 0.625rem 0.625rem rgba(177, 158, 97, 0.5);
  }
}

.p-cases-content__item:first-of-type {
  margin-top: initial;
}

@media screen and (min-width: 768px) {
  .p-cases-content__item.-right {
    margin-left: 4.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-cases-content__item.-more-right {
    margin-right: 0;
    margin-left: auto;
  }
}

.p-cases-content__title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-cases-content__title {
    font-size: 1.6875rem;
  }
}

.p-cases-content__text {
  margin-top: 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
}

.p-cases-content__img {
  position: absolute;
  bottom: -8.3125rem;
  left: 50%;
  transform: translateX(-50%);
  width: 62%;
  min-width: 14.6875rem;
  max-width: 14.6875rem;
}
@media screen and (min-width: 768px) {
  .p-cases-content__img {
    left: 0;
    transform: translateX(0);
    bottom: -4.375rem;
    max-width: 26.0625rem;
    width: 30%;
  }
}
@media screen and (min-width: 1024px) {
  .p-cases-content__img {
    bottom: -3.75rem;
    max-width: 26.0625rem;
  }
}

.p-cases-content__img img {
  aspect-ratio: 235/143;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-cases-content__img img {
    aspect-ratio: 418/255;
  }
}

.p-cases-item.-back-gray {
  background-color: rgba(58, 58, 58, 0.08);
  padding-top: 0.625rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-cases-item.-back-gray {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
}

@media screen and (min-width: 768px) {
  .p-cases-item__title-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
  }
}

.p-cases-item__number {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
  line-height: 1.2;
  color: rgba(58, 58, 58, 0.65);
  text-align: right;
}
@media screen and (min-width: 768px) {
  .p-cases-item__number {
    font-size: 4.375rem;
  }
}

.p-cases-item__title {
  font-size: 1.25rem;
  font-weight: 700;
  background-color: #3A3A3A;
  color: #fff;
  line-height: 1.5;
  padding: 0.3125rem 1.25rem 0.3125rem 1.5625rem;
  position: relative;
  margin-top: 0.625rem;
  max-width: fit-content;
}
@media screen and (min-width: 768px) {
  .p-cases-item__title {
    font-size: 1.6875rem;
    padding: 0.625rem 1.875rem 0.625rem 2.1875rem;
    margin-top: initial;
  }
}

.p-cases-item__title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0.625rem;
  background-color: #B19E61;
}
@media screen and (min-width: 768px) {
  .p-cases-item__title::before {
    width: 0.9375rem;
  }
}

.p-cases-item__content {
  margin-top: 1.25rem;
  width: 78%;
  margin-left: auto;
  margin-right: auto;
  min-width: 18.4375rem;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .p-cases-item__content {
    display: flex;
    width: 51.9%;
    margin-top: 1.6875rem;
    min-width: 44.375rem;
  }
}

@media screen and (min-width: 768px) {
  .p-cases-item__img {
    max-width: 18.75rem;
  }
}

.p-cases-item__img img {
  aspect-ratio: 295/187;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-cases-item__img img {
    aspect-ratio: 300/200;
  }
}

.p-cases-item__detail {
  border: solid 1px #3A3A3A;
  padding: 1.25rem 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-cases-item__detail {
    width: 100%;
    padding: 2.5rem 0.625rem 2.5rem 4.375rem;
  }
}

.p-cases-item__detail.-md {
  padding: 1.25rem 2.125rem;
}
@media screen and (min-width: 768px) {
  .p-cases-item__detail.-md {
    width: 100%;
    padding: 2.5rem 0.625rem 2.5rem 4.375rem;
  }
}

.p-cases-item__detail.-lg {
  padding: 1.25rem 3.25rem;
}
@media screen and (min-width: 768px) {
  .p-cases-item__detail.-lg {
    width: 100%;
    padding: 2.5rem 0.625rem 2.5rem 4.375rem;
  }
}

.p-cases-item__detail-wrap {
  display: flex;
  column-gap: 0.625rem;
  margin-top: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-cases-item__detail-wrap {
    column-gap: 1.25rem;
  }
}

.p-cases-item__detail-wrap:first-of-type {
  margin-top: initial;
}

.p-cases-item__detail-title {
  width: 100%;
  max-width: 4.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.1875rem;
  background-color: rgba(58, 58, 58, 0.1);
  text-align: center;
}

.p-cases-item__detail-text {
  font-size: 1rem;
  line-height: 1.5;
}

.p-cases-item__works {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-cases-item__works {
    margin-top: 3.125rem;
    display: flex;
    justify-content: space-between;
    column-gap: 2.5rem;
    align-items: center;
  }
}

.p-cases-item__before {
  padding: 0.9375rem;
  border-radius: 13px;
  background-color: #DBDBDB;
}
@media screen and (min-width: 768px) {
  .p-cases-item__before {
    width: 45%;
    padding: 0.9375rem 2.25rem 1.25rem;
    min-height: 12.5rem;
  }
}

.p-cases-item__sub-title {
  background-color: #fff;
  padding: 0.3125rem;
  border-radius: 17px;
  max-width: 9.5rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
}
.p-cases-item__sub-title.-before::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.6875rem;
  background-image: url(../src/images/page-cases/icon-before.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  aspect-ratio: 15/15;
  width: 0.625rem;
}

.p-cases-item__sub-title.-after::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.6875rem;
  background-image: url(../src/images/page-cases/icon-after.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  aspect-ratio: 15/15;
  width: 0.9375rem;
}

.p-cases-item__sub-title.-gold {
  color: #B19E61;
}

.p-cases-item__text {
  margin-top: 0.625rem;
  font-size: 1rem;
  line-height: 1.5;
}

.p-cases-item__text.-white {
  color: #fff;
}

.p-cases-item__text span {
  font-size: 1.25rem;
  font-weight: 500;
}

.p-cases-item__text span.-bold {
  font-size: 1rem;
  font-weight: 700;
}

.p-cases-item__after {
  padding: 0.9375rem;
  border-radius: 13px;
  background-color: #B19E61;
}
@media screen and (min-width: 768px) {
  .p-cases-item__after {
    width: 45%;
    padding: 0.9375rem 2.25rem 1.25rem;
    min-height: 14.125rem;
  }
}

.p-cases-item__arrow {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-top: 20px solid #3A3A3A;
  border-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .p-cases-item__arrow {
    margin: initial;
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid #555555;
    border-right: 0;
  }
}

.p-cases {
  padding-top: 2.5rem;
  padding-bottom: 3.125rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-cases {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
    position: relative;
  }
}

.p-cases::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  max-width: 37.5rem;
  background-image: url(../src/images/top/cases_back_sp.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  aspect-ratio: 359/431;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .p-cases::before {
    background-image: url(../src/images/top/cases_back.jpg);
    width: 93%;
    aspect-ratio: 1276/595;
    max-width: 84.375rem;
  }
}

@media screen and (min-width: 768px) {
  .p-cases__wrap {
    display: flex;
    column-gap: 2.8125rem;
  }
}

@media screen and (min-width: 768px) {
  .p-cases__text-box {
    width: 50%;
  }
}

.p-cases__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #B19E61;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-cases__title {
    font-size: 2.25rem;
  }
}

.p-cases__content {
  font-size: 1.5rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .p-cases__content {
    font-size: 1.6875rem;
  }
}

.p-cases__content span {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  color: #B19E61;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-left: 0.3125rem;
  line-height: 1.2;
}
.p-cases__content span.-sm {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4;
  color: #3A3A3A;
}
@media screen and (min-width: 768px) {
  .p-cases__content span.-sm {
    font-size: 1.25rem;
  }
}

.p-cases__text:first-of-type {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-cases__text:first-of-type {
    margin-top: 1.875rem;
  }
}

.p-cases__text {
  margin-top: 0.625rem;
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-cases__text {
    margin-top: 1.5625rem;
  }
}

.p-cases__img {
  margin-top: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-cases__img {
    margin-top: 4.375rem;
    width: 47%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

/*=========================
会社概要
================================*/
.p-company {
  background-color: rgba(58, 58, 58, 0.03);
  margin-top: 1.875rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-company {
    position: relative;
    background-color: #fff;
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
    margin-top: 3.125rem;
  }
}

@media screen and (min-width: 768px) {
  .p-company::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(58, 58, 58, 0.03);
    width: 78%;
    aspect-ratio: 1071/629;
    max-width: 66.9375rem;
  }
}

.p-company__title {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-company__title {
    font-size: 1.6875rem;
  }
}

.p-company__table {
  margin-top: 1.1875rem;
}
@media screen and (min-width: 768px) {
  .p-company__table {
    display: flex;
    flex-wrap: wrap;
    max-width: 51.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.875rem;
    justify-content: center;
  }
}

.p-company__text {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-company__text {
    width: 27%;
    border-bottom: 1px solid #d5d5d5;
    padding-left: 2.375rem;
  }
}

.p-company__text:not(:first-child) {
  margin-top: 0.5625rem;
}
@media screen and (min-width: 768px) {
  .p-company__text:not(:first-child) {
    margin-top: 0.875rem;
  }
}

.p-company__detail {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0.3125rem;
  padding-bottom: 0.5625rem;
  border-bottom: 1px solid #d5d5d5;
}
@media screen and (min-width: 768px) {
  .p-company__detail {
    width: 72%;
    padding-bottom: 0.875rem;
    margin-top: 0.875rem;
    padding-right: 2.375rem;
  }
}

.p-company__detail:first-of-type {
  margin-top: initial;
}
.p-company__detail:last-child,
.p-company__text:last-of-type {
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  .p-company__detail:last-child,
  .p-company__text:last-of-type {
    border-bottom: none;
  }
}

.p-company__detail span {
  display: block;
  font-size: 1.125rem;
  color: rgba(58, 58, 58, 0.7);
}
@media screen and (min-width: 768px) {
  .p-company__detail span {
    font-size: 0.875rem;
  }
}

.p-form__title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.43;
}
.p-form__text {
  font-size: 0.875rem;
  line-height: 1.43;
  margin-top: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-form__text {
    margin-top: 1.25rem;
  }
}

.p-form__title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.43;
  margin-top: 1.25rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-form__title {
    margin-top: 1.875rem;
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .p-form__title:first-of-type {
    margin-top: 2.5rem;
  }
}

.p-form .required {
  background-color: #B19E61;
  color: #fff;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-block;
  margin-left: 1.25rem;
}
.p-form input,
.p-form textarea {
  background-color: rgba(58, 58, 58, 0.03);
  margin-top: 0.625rem;
  width: 100%;
  padding: 0.625rem;
}

.p-form .wpcf7-form-control-wrap {
  margin-top: 0.625rem;
  display: block;
}

.p-form .wpcf7-list-item {
  margin-left: initial;
}

.p-form .wpcf7-list-item input[type=checkbox],
.p-form .wpcf7-list-item input[type=radio] {
  border: solid 1px #3A3A3A;
  background-color: #fff;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0rem;
  margin: 0;
  margin-right: 0.3125rem;
}

.p-form .wpcf7-list-item {
  margin-right: 1.25rem;
  margin-left: initial;
}

.p-member {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-image: url(../src/images/top/member_back_sp.jpg);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% 100%;
}
@media screen and (min-width: 768px) {
  .p-member {
    background-image: url(../src/images/top/member_back.jpg);
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
}

.p-member__wrap {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-member__wrap {
    display: flex;
    column-gap: 4.375rem;
    max-width: 52.625rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3.125rem;
  }
}

.p-member__people {
  width: 61%;
  margin-left: auto;
  margin-right: auto;
  min-width: 14.5625rem;
}
@media screen and (min-width: 768px) {
  .p-member__people {
    width: 39%;
  }
}

.p-member__people img {
  aspect-ratio: 233/216;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-member__people img {
    aspect-ratio: 323/390;
  }
}

.p-member__name {
  margin-top: 0.625rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-member__name {
    margin-top: 1.25rem;
  }
}

.p-member__item {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-member__item {
    width: 52%;
    margin-top: initial;
  }
}

.p-member__title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  z-index: 1;
  padding: 0.125rem 0.8125rem;
  max-width: fit-content;
}
.p-member__title::before {
  transform: skewX(-18deg);
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #fff;
}

.p-member__text {
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1.75rem;
}
@media screen and (min-width: 768px) {
  .p-member__text {
    margin-top: 0.9375rem;
  }
}

.p-member__text:first-of-type {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-member__text:first-of-type {
    margin-top: 0.9375rem;
  }
}

.p-member__btn {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-member__btn {
    margin-top: 3.125rem;
  }
}

.p-message {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-image: url(../src/images/top/message_back_sp.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-message {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
    background-image: url(../src/images/top/message_back.jpg);
  }
}

.p-message__detail {
  margin-top: 1.875rem;
  max-width: 46.875rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .p-message__detail {
    margin-top: 3.125rem;
  }
}

.p-message__text {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  word-break: keep-all;
  overflow-wrap: anywhere;
  line-height: 1.5;
}
.p-message__text:not(:first-child) {
  margin-top: 1.75rem;
}
.p-message__btn {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-message__btn {
    margin-top: 3.125rem;
  }
}

@media screen and (min-width: 768px) {
  .p-page-blog__wrap {
    display: flex;
    justify-content: space-between;
    column-gap: 7.5rem;
    align-items: flex-start;
    margin-top: 3.125rem;
  }
}

.p-page-blog__body {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-page-blog__body {
    margin-top: initial;
  }
}

.p-page-blog__list {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-page-blog__list {
    margin-top: initial;
  }
}

.p-page-blog__pagenavi {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-page-blog__pagenavi {
    margin-top: 3.75rem;
  }
}

.p-page-blog__sidebar {
  margin-top: 3.125rem;
  width: 63%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fafafa;
}
@media screen and (min-width: 768px) {
  .p-page-blog__sidebar {
    margin-top: initial;
    max-width: 13.75rem;
  }
}

.p-page-blog__cate-title {
  padding: 0.1875rem;
  background-color: #3A3A3A;
  color: #fff;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

.p-page-blog__box {
  padding: 1.25rem 1.25rem 2.5rem;
}

.p-page-blog__list {
  margin-top: 0.9375rem;
}

.p-page-blog__list:first-of-type {
  margin-top: initial;
}

.p-page-blog__cate {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.43;
  display: block;
  padding-left: 1.5625rem;
  position: relative;
}

.p-page-blog__cate::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 0.9375rem;
  height: 0.125rem;
  background-color: #3A3A3A;
}

.p-page-blog__cate.-active::before {
  display: none;
}

.p-page-brain {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-brain {
    margin-top: 6.875rem;
  }
}

.p-page-brain__wrap {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-page-brain__wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3.125rem;
    column-gap: 3.4375rem;
  }
}

.p-page-brain__item {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-brain__item {
    margin-top: initial;
    width: 30%;
  }
}

.p-page-brain__item:first-of-type {
  margin-top: initial;
}

.p-page-brain__img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .p-page-brain__img {
    width: 100%;
  }
}

.p-page-brain__img img {
  aspect-ratio: 275/208;
  object-fit: cover;
}

.p-page-brain__name {
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 0.625rem;
  text-align: center;
  padding-left: 1.1875rem;
  padding-right: 1.1875rem;
  padding-bottom: 0.25rem;
  width: 100%;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #B19E61;
}
@media screen and (min-width: 768px) {
  .p-page-brain__name {
    font-size: 1.25rem;
    margin-top: 1.25rem;
    padding-left: initial;
    padding-right: initial;
    max-width: 15.3125rem;
    margin-left: 0;
    margin-right: auto;
    text-align: left;
  }
}

.p-page-brain__detail {
  margin-top: 0.875rem;
  font-size: 1rem;
  line-height: 1.5;
}

.p-page-brain__content {
  display: flex;
}

.p-page-brain__content:first-of-type {
  margin-top: 0.625rem;
}

.p-page-brain__content-title {
  width: 3.375rem;
  font-size: 1rem;
}
.p-page-brain__content-text {
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .p-page-brain__content-text {
    width: 83%;
  }
}

@media screen and (min-width: 768px) {
  .p-page-brain__name-wrap {
    display: flex;
    column-gap: 2.3125rem;
    align-items: center;
  }
}

.p-page-brain__name-wrap img {
  aspect-ratio: 30/30;
  object-fit: cover;
  width: 100%;
  max-width: 1.875rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .p-page-brain__name-wrap img {
    margin: initial;
    object-fit: contain;
    margin-right: 1.125rem;
  }
}

.p-page-brain__detail-wrap {
  display: flex;
}

.p-page-brain__detail-title {
  width: 5rem;
}

.p-page-cases__item.-top {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-cases__item.-top {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
}

.p-page-gallery {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-gallery {
    margin-top: 6.875rem;
  }
}

.p-page-gallery__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 53.125rem;
  margin-left: auto;
  margin-right: auto;
  column-gap: 1.375rem;
  row-gap: 1.25rem;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-page-gallery__wrap {
    column-gap: 3.125rem;
    row-gap: 3.125rem;
    margin-top: 3.125rem;
  }
}

.p-page-gallery__img {
  width: 28.7%;
}

.p-page-gallery__img img {
  aspect-ratio: 250/250;
  object-fit: cover;
}

.p-page-member {
  position: relative;
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-member {
    margin-top: 6.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-page-member::before {
    content: "";
    position: absolute;
    top: 16.625rem;
    right: 0;
    background-color: rgba(58, 58, 58, 0.03);
    width: 78%;
    aspect-ratio: 1071/629;
    max-width: 66.9375rem;
  }
}

.p-page-member__wrap {
  margin-top: 1.75rem;
}
@media screen and (min-width: 768px) {
  .p-page-member__wrap {
    display: flex;
    column-gap: 5rem;
    margin-top: 3.125rem;
  }
}

.p-page-member__wrap.-reverse {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-page-member__wrap.-reverse {
    margin-top: 1.875rem;
    position: relative;
  }
}

.p-page-member__name {
  width: 73%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .p-page-member__name {
    width: 32.8%;
  }
}

.p-page-member__name img {
  aspect-ratio: 275/208;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-page-member__name img {
    aspect-ratio: 361/274;
  }
}

.p-page-member__name-text {
  text-align: center;
  margin-top: 0.9375rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-page-member__name-text {
    font-size: 1.25rem;
  }
}

.p-page-member__sns {
  display: flex;
  justify-content: center;
  column-gap: 6.25rem;
  margin-top: 1.5625rem;
}
.p-page-member__sns img {
  aspect-ratio: 32/32;
  object-fit: cover;
  max-width: 2rem;
}
@media screen and (min-width: 768px) {
  .p-page-member__content {
    width: 54%;
  }
}

.p-page-member__content:first-of-type {
  margin-top: 1.875rem;
}

@media screen and (min-width: 768px) {
  .p-page-member__content.-lg {
    width: 100%;
  }
}

.p-page-member__title {
  padding-bottom: 0.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  border-bottom: 1px solid #B19E61;
  margin-top: 1.25rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-page-member__title {
    font-size: 1.25rem;
    margin-top: 1.875rem;
    max-width: 20rem;
  }
}

.p-page-member__title:first-of-type {
  margin-top: initial;
}

.p-page-member__text {
  font-size: 1rem;
  margin-top: 0.5625rem;
}
.p-page-member__img {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-page-member__img {
    margin-top: initial;
    position: absolute;
    max-width: 39.3125rem;
    top: 3.75rem;
    right: 0;
  }
}

.p-page-member__img img {
  aspect-ratio: 343/236;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-page-member__img img {
    aspect-ratio: 629/431;
  }
}

.p-page-contact__wrap {
  margin-top: 2.1875rem;
}
@media screen and (min-width: 768px) {
  .p-page-contact__wrap {
    display: flex;
    justify-content: center;
    column-gap: 3.125rem;
    margin-top: 3.125rem;
  }
}

.p-page-contact__item {
  margin-top: 1.875rem;
  position: relative;
  background-color: rgba(58, 58, 58, 0.03);
  padding: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .p-page-contact__item {
    margin-top: initial;
    width: 33%;
    padding: 1.875rem 1.5625rem 1.5625rem;
  }
}

.p-page-contact__item::before {
  position: absolute;
  inset: 5px -5px -5px 5px;
  border: 1px solid rgba(58, 58, 58, 0.45);
  content: "";
}

.p-page-contact__item:first-of-type {
  margin-top: initial;
}

.p-page-contact__img {
  max-width: 1.875rem;
  margin-left: auto;
  margin-right: auto;
}
.p-page-contact__img img {
  aspect-ratio: 30/30;
  object-fit: cover;
}

.p-page-contact__title {
  text-align: center;
  margin-top: 0.9375rem;
  font-size: 1.25rem;
  font-weight: 500;
}
.p-page-contact__detail {
  text-align: center;
  margin-top: 1.25rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.p-page-contact__detail span {
  font-family: "Rubik", sans-serif;
}

.p-page-contact__form {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-contact__form {
    margin-top: 6.875rem;
    max-width: 43.75rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) {
  .p-page-privacy__inner {
    max-width: 59.375rem;
  }
}

.p-page-privacy__text {
  margin-top: 1.875rem;
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-page-privacy__text {
    margin-top: 3.125rem;
  }
}

.p-page-privacy__title {
  margin-top: 1.875rem;
  font-size: 1.25rem;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .p-page-privacy__title {
    margin-top: 2.5rem;
  }
}

.p-page-privacy__detail {
  margin-top: 0.625rem;
  font-size: 1rem;
  line-height: 1.5;
}

.p-page-privacy__lists {
  margin-top: 0.625rem;
}

.p-page-privacy__list {
  font-size: 1rem;
  line-height: 1.5;
}

.p-page-privacy__detail .-link {
  color: #3271b8;
}

.p-page-recruit__wrap {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-page-recruit__wrap {
    display: flex;
    justify-content: center;
    column-gap: 3.75rem;
    margin-top: 3.125rem;
  }
}

@media screen and (min-width: 768px) {
  .p-page-recruit__wrap.-reverse {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 768px) {
  .p-page-recruit__img {
    width: 50.9%;
  }
}

.p-page-recruit__img img {
  aspect-ratio: 343/193;
  object-fit: cover;
}

.p-page-recruit__box {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-page-recruit__box {
    margin-top: initial;
    width: 43.6%;
  }
}

.p-page-recruit__title {
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .p-page-recruit__title {
    font-size: 1.6875rem;
  }
}

.p-page-recruit__text {
  line-height: 1.5;
  font-size: 1rem;
  margin-top: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-page-recruit__text {
    margin-top: 1.875rem;
  }
}

.p-page-recruit__form {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-recruit__form {
    margin-top: 6.875rem;
    max-width: 43.75rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-page-service__body {
  margin-top: 1.875rem;
  background-color: rgba(58, 58, 58, 0.03);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-page-service__body {
    margin-top: 3.125rem;
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
}

.p-page-service__text {
  margin-top: 1.75rem;
  line-height: 1.5;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .p-page-service__text {
    text-align: center;
    margin-top: 1.4375rem;
  }
}

.p-page-service__text:first-of-type {
  margin-top: initial;
}

.p-page-service__text .-marker {
  background: linear-gradient(transparent 75%, rgba(177, 158, 97, 0.8) 50%);
}

.p-page-service__text .-lg {
  font-size: 1.6875rem;
  color: #B19E61;
  line-height: 1.5;
  font-weight: 700;
}

.p-page-service__img {
  margin-top: 1.25rem;
  max-width: 25.1875rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .p-page-service__img {
    margin-top: 1.875rem;
    max-width: 35.25rem;
  }
}

.p-page-service__img img {
  aspect-ratio: 323/130;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-page-service__img img {
    aspect-ratio: 564/184;
  }
}

.p-page-service__item {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-page-service__item {
    margin-top: 6.875rem;
  }
}

.p-recruit-form__text {
  font-size: 0.875rem;
  line-height: 1.43;
}

.p-recruit-form__title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.43;
  margin-top: 1.25rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-recruit-form__title {
    margin-top: 1.875rem;
  }
}

.p-recruit-form__title:first-of-type {
  margin-top: 2.5rem;
}

.p-recruit-form .required {
  background-color: #B19E61;
  color: #fff;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-block;
  margin-left: 1.25rem;
}
.p-recruit-form input,
.p-recruit-form textarea {
  background-color: rgba(58, 58, 58, 0.03);
  margin-top: 0.625rem;
  width: 100%;
  padding: 0.625rem;
}

.p-recruit-form .wpcf7-form-control-wrap {
  margin-top: 0.625rem;
  display: block;
}

.p-recruit-form .wpcf7 input[type=checkbox],
.p-recruit-form .wpcf7 input[type=radio] {
  border: solid 1px #3A3A3A;
  background-color: #fff;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0rem;
  margin: 0;
  margin-right: 0.3125rem;
  position: inherit;
}

.p-recruit-form .wpcf7-list-item {
  margin-right: 1.25rem;
  margin-left: initial;
}

.p-service-item__img {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-service-item__img {
    margin-top: 2.5rem;
    max-width: 53.25rem;
  }
}

.p-service-item__img.-right {
  margin-left: auto;
  margin-right: 0;
}

.p-service-item__img img {
  aspect-ratio: 343/204;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-service-item__img img {
    aspect-ratio: 852/165;
  }
}

.p-service {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-service {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
}

.p-service__inner {
  position: relative;
}

.p-service__inner::before {
  content: "Service";
  position: absolute;
  top: -2.5rem;
  left: 1rem;
  font-style: italic;
  font-family: "Rubik", sans-serif;
  line-height: 1.51;
  font-weight: 500;
  color: rgba(58, 58, 58, 0.1);
  font-size: 3.4375rem;
}
@media screen and (min-width: 768px) {
  .p-service__inner::before {
    top: -5rem;
    left: -1.5625rem;
    font-size: 9rem;
  }
}

.p-service__item {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-service__item {
    margin-top: 3.125rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 5.375rem;
  }
}

@media screen and (min-width: 768px) {
  .p-service__item--reverse {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 768px) {
  .p-service__img {
    width: 51.8%;
  }
}

@media screen and (min-width: 768px) {
  .p-service__img.-page {
    width: 44.5%;
  }
}

.p-service__img img {
  aspect-ratio: 343/189;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .p-service__img img {
    aspect-ratio: 570/240;
  }
}

.p-service__img.-page img {
  aspect-ratio: 343/203;
}
@media screen and (min-width: 768px) {
  .p-service__img.-page img {
    aspect-ratio: 490/290;
  }
}

.p-service__text-box {
  margin-top: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-service__text-box {
    width: 40%;
  }
}

.p-service__text-box.-page {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-service__text-box.-page {
    margin-top: initial;
    width: 50.9%;
  }
}

.p-service__title-box {
  display: flex;
  align-items: center;
  column-gap: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-service__title-box {
    column-gap: 1.25rem;
  }
}

.p-service__title-box.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-service__title-box.u-desktop {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .p-service__title-box.u-mobile {
    display: none;
  }
}

.p-service__number {
  font-family: "Rubik", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: rgba(177, 158, 97, 0.5);
}
@media screen and (min-width: 768px) {
  .p-service__number {
    font-size: 3.125rem;
  }
}

.p-service__title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-service__title {
    font-size: 1.6875rem;
  }
}

.p-service__text {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-service__text {
    margin-top: 0.625rem;
  }
}

.p-service__text.-top {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-service__text.-top {
    margin-top: 1.875rem;
  }
}

.p-service__text.-page:not(:first-of-type) {
  margin-top: 0.625rem;
}

.p-service__text span {
  background: linear-gradient(transparent 75%, rgba(177, 158, 97, 0.8) 50%);
}

.p-service__text span.-lg {
  font-size: 1.25rem;
  font-weight: 700;
  color: #B19E61;
  background: none;
}

.p-service__btn {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-service__btn {
    margin-top: 3.125rem;
  }
}

.p-service__catch {
  padding: 0.1875rem 0.9375rem;
  background-color: #B19E61;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  margin-top: 1.4375rem;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .p-service__catch {
    padding: 0.1875rem 1.25rem;
    margin-top: 2.0625rem;
  }
}

.p-service__list {
  padding-left: 1.0625rem;
  position: relative;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .p-service__list {
    text-align: center;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-service__list::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0;
  background-image: url(../src/images/page-service/check-icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  aspect-ratio: 12/12;
  width: 0.75rem;
}
.p-service__list:not(:first-of-type) {
  margin-top: 0.3125rem;
}
@media screen and (min-width: 768px) {
  .p-service__list:not(:first-of-type) {
    margin-top: 0.9375rem;
  }
}

.p-service__list span {
  background: linear-gradient(transparent 75%, rgba(58, 58, 58, 0.17) 50%);
}

.p-voice {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-voice {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
}

.p-voice__wrap {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-voice__wrap {
    display: flex;
    column-gap: 3.4375rem;
  }
}

.p-voice__item {
  background-color: rgba(58, 58, 58, 0.05);
  padding: 1.25rem 1.625rem;
}
@media screen and (min-width: 768px) {
  .p-voice__item {
    padding: 1.25rem;
  }
}

.p-voice__item:not(:first-child) {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-voice__item:not(:first-child) {
    margin-top: initial;
  }
}

.p-voice__img {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-voice__img {
    max-width: 18.125rem;
  }
}

.p-voice__img img {
  object-fit: cover;
  aspect-ratio: 290/160;
}
.p-voice__title {
  padding: 0.3125rem 0.625rem;
  background-color: rgba(177, 158, 97, 0.85);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 0;
}

.p-voice__text {
  margin-top: 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
}
.p-voice__btn {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-voice__btn {
    margin-top: 3.125rem;
  }
}