@use "global" as *;

.p-page-member {
    position: relative;
    margin-top: rem(50);

    @include mq("md") {
        margin-top: rem(110);
    }
}

.p-page-member::before {

    @include mq("md") {
        content: "";
        position: absolute;
        top: rem(266);
        right: 0;
        background-color: rgba($color: $black, $alpha: 0.03);
        width: 78%;
        aspect-ratio: 1071/629;
        max-width: rem(1071);
    }
}

.p-page-member__inner {}




.p-page-member__wrap {
    margin-top: rem(28);

    @include mq("md") {
        display: flex;
        column-gap: rem(80);
        margin-top: rem(50);
    }
}

.p-page-member__wrap.-reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-top: rem(20);
    @include mq("md") {
        margin-top: rem(30);
        position: relative;
    }
}

.p-page-member__name {
    width: 73%;
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {
        width: 32.8%;
    }
}

.p-page-member__name img {
    aspect-ratio: 275/208;
    object-fit: cover;

    @include mq("md") {
        aspect-ratio: 361/274;
    }
}

.p-page-member__photo {}


.p-page-member__name-text {
    text-align: center;
    margin-top: rem(15);
    font-size: rem(18);
    font-weight: $medium;
    line-height: 1.5;

    @include mq("md") {
        font-size: rem(20);
    }
}

.p-page-member__sns {
    display: flex;
    justify-content: center;
    column-gap: rem(100);
    margin-top: rem(25);

    @include mq("md") {}
}

.p-page-member__sns img {
    aspect-ratio: 32/32;
    object-fit: cover;
    max-width: rem(32);

    @include mq("md") {}
}


.p-page-member__content {
    @include mq("md") {
     width: 54%;   
    }
}
.p-page-member__content:first-of-type {
    margin-top: rem(30);
}
.p-page-member__content.-lg {
    @include mq("md") {
        width: 100%;   
       }
}

.p-page-member__title {
    padding-bottom: rem(4);
    font-size: rem(18);
    font-weight: $medium;
    border-bottom: 1px solid $gold;
    margin-top: rem(20);
    line-height: 1.5;

    @include mq("md") {
        font-size: rem(20);
        margin-top: rem(30);
        max-width: rem(320);
    }
}

.p-page-member__title:first-of-type {
    margin-top: initial;
}

.p-page-member__text {
    font-size: rem(16);
    margin-top: rem(9);
    @include mq("md") {}
}

.p-page-member__img {
    margin-top: rem(30);
    @include mq("md") {
        margin-top: initial;
        position: absolute;
        max-width: rem(629);
        top: rem(60);
        right: 0;
    }
}

.p-page-member__img img {
    aspect-ratio: 343/236;
    object-fit: cover;
    @include mq("md") {
        aspect-ratio: 629/431;
    }
}