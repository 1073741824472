@use "global" as *;

.c-banner-recruit {
    background-image: url(../src/images/top/recruit_base_sp.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: rem(40);
    padding-bottom: rem(40);

    @include mq("md") {
        background-image: url(../src/images/top/recruit_base.jpg);
        padding-top: rem(70);
        padding-bottom: rem(70);
    }
}



.c-banner-recruit__wrap {
    @include mq("md") {
        display: flex;
        column-gap: rem(55);
    }
}

.c-banner-recruit__item {
    @include mq("md") {
        width: 30%;
    }
}

.c-banner-recruit__icon {
    aspect-ratio: 100/100;
    object-fit: cover;
    max-width: rem(100);

    @include mq("md") {}
}

.c-banner-recruit__text {
    margin-top: rem(20);
    line-height: 1.5;
    font-size: rem(16);

    @include mq("md") {
        margin-top: rem(30);
    }
}

.c-banner-recruit__img {
    margin-top: rem(30);

    @include mq("md") {
        width: 65%;
        margin-top: initial;
    }
}

.c-banner-recruit__img img {
    aspect-ratio: 343/124;
    object-fit: cover;
    @include mq("md") {
        aspect-ratio: 715/258;
    }
}