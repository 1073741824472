@use "global" as *;

.l-page-header {
    margin-top: rem(60);

    @include mq("md") {
        margin-top: rem(80);
    }
}

.l-page-header img {
    aspect-ratio: 1366/470;

    @include mq("md") {
        aspect-ratio: 1366 / 420;
    }
}