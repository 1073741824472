@use "global" as *;

.wp-pagenavi {
    clear: both;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: rem(16);
    column-gap: rem(10);
}

.p-page-blog__pagenavi .wp-pagenavi a,
.p-page-blog__pagenavi .wp-pagenavi span {
    color: $black;
    background-color: $white;
    padding: rem(7) rem(15);
    border: solid 1px $black;
    text-align: center;
    text-decoration: none;
    display: block;
    font-family: $second-font-family;
    font-size: rem(16);
}

.wp-pagenavi a:hover {
    color: $white;
    background-color: $black;
    border-color: $black;
}

.wp-pagenavi span.current {
    color: $white;
    background-color: $black;
    border-color: $black;
    font-weight: bold;
}

.p-page-blog__pagenavi .wp-pagenavi .pages {
display: none;
}

.p-page-blog__pagenavi .wp-pagenavi .nextpostslink,
.p-page-blog__pagenavi .wp-pagenavi .previouspostslink {
         border: none;
         padding: rem(7) rem(0);
}
.p-page-blog__pagenavi .wp-pagenavi .nextpostslink:hover,
.p-page-blog__pagenavi .wp-pagenavi .previouspostslink:hover {
    background-color: initial;
    border-color: initial;
    opacity: .7;
    color: $black;
}