@use "global" as *;

.p-page-privacy {}

.p-page-privacy__inner {
    @include mq("md") {
     max-width: rem(950);   
    }
}



.p-page-privacy__text {
    margin-top: rem(30);
    font-size: rem(16);
    line-height: 1.5;

    @include mq("md") {
        margin-top: rem(50);
    }
}

.p-page-privacy__title {
    margin-top: rem(30);
    font-size: rem(20);
    font-weight: $medium;

    @include mq("md") {
        margin-top: rem(40);
    }
}

.p-page-privacy__detail {
    margin-top: rem(10);
    font-size: rem(16);
    line-height: 1.5;
}

.p-page-privacy__lists {
    margin-top: rem(10);
}

.p-page-privacy__list {
    font-size: rem(16);
    line-height: 1.5;
}

.p-page-privacy__detail .-link {
    color: $blue;
}