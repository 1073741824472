@use "global" as *;

.hamburger {
    padding: rem(13) rem(21);
}

.hamburger span {
    display: block;
    width: rem(26);
    height: rem(1);
    background-color: $black;
    border-radius: rem(2);
    transition: .3s;

}

.hamburger span:nth-child(2) {
    margin: rem(10) 0;
}

.hamburger.open span:nth-child(1) {
    transform: translate(rem(3), rem(0)) rotate(45deg);
    transform-origin: left;
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: translate(rem(3), rem(3)) rotate(-45deg);
    transform: translate(0.1875rem, -0.20875rem) rotate(-45deg);
    transform-origin: left;
}