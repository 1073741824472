@use "global" as *;

.p-service-item__img {
    margin-top: rem(20);

    @include mq("md") {
        margin-top: rem(40);
        max-width: rem(852);
    }
}

.p-service-item__img.-right {
    margin-left: auto;
    margin-right: 0;
}

.p-service-item__img img {
    aspect-ratio: 343/204;
    object-fit: cover;

    @include mq("md") {
        aspect-ratio: 852/165;
    }
}