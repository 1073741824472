@use "global" as *;

.p-voice {
    margin-top: rem(50);
    margin-bottom: rem(50);
    @include mq("md") {
        margin-top: rem(110);
        margin-bottom: rem(110);
    }
}

.p-voice__inner {}

.p-voice__wrap {
    margin-top: rem(30);
    @include mq("md") {
     display: flex;
     column-gap: rem(55);
    }
}

.p-voice__item {
    background-color: rgba($color: $black, $alpha: .05);
    padding: rem(20) rem(26);
    @include mq("md") {
      padding: rem(20);   
    }
}

.p-voice__item:not(:first-child) {
    margin-top: rem(30);
    @include mq("md") {
        margin-top: initial;
    }
}

.p-voice__img {
    position: relative;
@include mq("md") {
    max-width: rem(290);
}
}
.p-voice__img img {
    object-fit: cover;
    aspect-ratio: 290/160;  
@include mq("md") {
 
}
}
.p-voice__title {
    padding: rem(5) rem(10);
    background-color: rgba($color: $gold, $alpha: .85);
    color: $white;
    font-size: rem(14);
    font-weight: $medium;
    position: absolute;
    bottom: 0;
    left: 0;
}

.p-voice__text {
    margin-top: rem(15);
    font-size: rem(16);
    line-height: 1.5;
    @include mq("md") {
        
    }
}

.p-voice__btn {
   margin-top: rem(30);
   @include mq("md") {
    margin-top: rem(50);
   }
}