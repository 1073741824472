@use "global" as *;

.p-recruit-form__text {
    font-size: rem(14);
    line-height: 1.43;
}

.p-recruit-form__title {
    font-size: rem(16);
    font-weight: $medium;
    line-height: 1.43;
    margin-top: rem(20);
    display: block;
    @include mq("md") {
        margin-top: rem(30);
    }
}
.p-recruit-form__title:first-of-type {
    margin-top: rem(40);
}
.p-recruit-form .required {
    background-color: $gold;
    color: $white;
    padding: rem(2) rem(10);
    font-size: rem(12);
    font-weight: $medium;
    display: inline-block;
    margin-left: rem(20);
    @include mq("md") {
        
    }
}
.p-recruit-form input,
.p-recruit-form textarea {
    background-color: rgba($color: $black, $alpha: 0.03);
    margin-top: rem(10);
    width: 100%;
     padding: rem(10);
}
.p-recruit-form .wpcf7-form-control-wrap {
    margin-top: rem(10);
    display: block;
}

.p-recruit-form .wpcf7 input[type=checkbox], 
.p-recruit-form .wpcf7 input[type=radio] {
    border: solid 1px $black;
    background-color: $white;
    width: rem(10);
    height: rem(10);
     padding: rem(0);
     margin: 0;
     margin-right: rem(5);
     position: inherit;
}
.p-recruit-form .wpcf7-list-item {
    margin-right: rem(20);
    margin-left: initial;
}

span.wpcf7-form-control-wrap {

}