@use "global" as *;


/*=========================
会社概要
================================*/

.p-company {
    background-color: rgba($color: $black, $alpha: 0.03);
    margin-top: rem(30);
    padding-top: rem(40);
    padding-bottom: rem(40);
    @include mq("md") {
        position: relative;
        background-color: $white;
        padding-top: rem(70);
        padding-bottom: rem(70);
        margin-top: rem(50);
    }
}
.p-company::before {
    @include mq("md") {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: $black, $alpha: 0.03);
        width: 78%;
        aspect-ratio: 1071/629;
        max-width: rem(1071);
    }
}
.p-company__title {
    font-size: rem(20);
    font-weight: $bold;
    text-align: center;
    @include mq("md") {
        font-size: rem(27);
}}

.p-company__table {
    margin-top: rem(19);
    @include mq("md") {
        display: flex;
        flex-wrap: wrap;
        max-width: rem(824);
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(30);
        justify-content: center;
        
    }
}

.p-company__text {

    font-size: rem(16);
    line-height: 1.5;
    @include mq("md") {
        width: 27%;
        border-bottom: 1px solid #d5d5d5;
        padding-left: rem(38);
    }
}

.p-company__text:not(:first-child) {
    margin-top: rem(9);
    @include mq("md") {
        margin-top: rem(14);
    }
}



.p-company__detail {

    font-size: rem(16);
    line-height: 1.5;
    margin-top: rem(5);
    padding-bottom: rem(9);
    border-bottom: 1px solid #d5d5d5;
    @include mq("md") {
        width: 72%;
        padding-bottom: rem(14);
        margin-top: rem(14);
        padding-right: rem(38);
    }

}

.p-company__detail:first-of-type {
    margin-top: initial;
    @include mq("md") {
        // margin-top: rem(12);
    }
}

.p-company__detail:last-child,
.p-company__text:last-of-type {
    border-bottom: none;
    @include mq("md") {
        border-bottom: none;
    }
}

// .p-company__detail::before {
//   position: absolute;
//   content: attr(data-en);
//   top: -20px;
//   left: 50%;
//   transform: translatex(-50%);
//   font-size: 16px;
// }
.p-company__detail span {
    display: block;
    font-size: rem(18);
    color: rgba($color: $black, $alpha: 0.7);

    @include mq("md") {
        font-size: rem(14);
    }
}