@use "global" as *;

.p-page-brain {
    margin-top: rem(50);

    @include mq("md") {
        margin-top: rem(110);
    }
}

.p-page-brain__inner {}


.p-page-brain__wrap {
    margin-top: rem(30);

    @include mq("md") {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: rem(50);
        column-gap: rem(55);
    }
}

.p-page-brain__item {
    margin-top: rem(50);

    @include mq("md") {
        margin-top: initial;
        width: 30%;
    }
}

.p-page-brain__item:first-of-type {
    margin-top: initial;
}

.p-page-brain__img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {
        width: 100%;
    }
}


.p-page-brain__img img {
    aspect-ratio: 275 / 208;
    object-fit: cover;
}

.p-page-brain__name {
    font-size: rem(18);
    font-weight: $medium;
    margin-top: rem(10);
    text-align: center;
    padding-left: rem(19);
    padding-right: rem(19);
    padding-bottom: rem(4);
    width: 100%;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid $gold;

    @include mq("md") {
        font-size: rem(20);
        margin-top: rem(20);
        padding-left: initial;
        padding-right: initial;
        max-width: rem(245);
        margin-left: 0;
        margin-right: auto;
        text-align: left;
    }
}

.p-page-brain__detail {
    margin-top: rem(14);
    font-size: rem(16);
    line-height: 1.5;
}

.p-page-brain__content {
    display: flex;

}

.p-page-brain__content:first-of-type {
    margin-top: rem(10);
}

.p-page-brain__content-title {
    width: rem(54);
    font-size: rem(16);

    @include mq("md") {
      
    }
}

.p-page-brain__content-text {
    font-size: rem(16);

    @include mq("md") {
        width: 83%;
    }
}

.p-page-brain__name-wrap {
    @include mq("md") {
        display: flex;
        column-gap: rem(37);
        align-items: center;
    }
}

.p-page-brain__name-wrap img {
    aspect-ratio: 30/30;
    object-fit: cover;
    width: 100%;
    max-width: rem(30);
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(16);

    @include mq("md") {
        margin: initial;
        object-fit: contain;
        margin-right: rem(18);
    }
}


.p-page-brain__detail-wrap {
    display: flex;

}

.p-page-brain__detail-title {
    width: rem(80);
}

.p-page-brain__detail-text {}