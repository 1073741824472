@use "global" as *;

.header-nav {
    width: 100%;
    height: 100vh;

    @include mq("md") {
        background-color: initial;
        margin-top: initial;
        width: initial;
        height: inherit;
        overflow-y: initial;
    }
}

.header-nav__items {
    height: inherit;
    display: flex;
    flex-direction: column;
    margin-top:rem(140);
    // align-items: center;
    min-height: rem(400);
    @include mq("md") {
        margin-top: initial;
        min-height: initial;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.header-nav__item {
    position: relative;
    text-align: center;
    margin-top: rem(30);
    @include mq("md") {
        height: inherit;
        margin-top: initial;
        // margin-left:rem(32) ;
        white-space: nowrap
    }
}
.header-nav__item:first-of-type {
    margin-top: initial;
}
.header-nav__item a {
    display: block;
    height: inherit;
    // padding-top: rem(21);
    // padding-bottom: rem(21);
    font-size: rem(16);
    font-weight: $semibold;
    color: $white;
    @include mq("md") {
        color: $black;
        padding: rem(21) rem(25);
    }
}
.header-nav__item a::after,
.header-nav__btn a::after {
        font-family: "Noto Sans JP", sans-serif;
        content: attr(data-title);
        display: block;
        color: $white;
        font-size: rem(12);
        font-weight: $medium;
        transition: .3s;
        @include mq("md") {
            color: $black;
        }
}
.header-nav__btn a::after {
    color: $white;
}
.header-nav__btn a:hover::after {
    transition: .3s;
    @include mq("md") {
     color: $black;   
    }
}
.header-nav__btn {
    margin-top: rem(30);
    margin-left: auto;
    margin-right: auto;
    @include mq("md") {
     margin-top: initial;   
    }
}
.header-nav__btn a{
    font-size: rem(16);
    color: $white;
    display:inline-block;
    background-color: $gold;
    text-align: center;
    width: 100%;
    max-width: rem(150);
    padding: rem(8) rem(45) rem(6) rem(40);
    transition: .3s;
         border: 1px solid $gold;
         position: relative;
    @include mq("md") {
    line-height: 1.5;
        font-size: rem(16);
        height: inherit;
        border: 1px solid $black;
        background-color: $black;
        max-width: rem(130);
        margin-right: rem(5);
        padding: rem(8) rem(36) rem(6) rem(30);
    }
}

.header-nav__btn a:hover{
    opacity: 1;
    @include mq("md") {
        color: $black;
        background-color: $white;
        transition: .3s;
    }
}

.header-nav__btn a::before {
    content: "";
    position: absolute;
    top: 50%;
    right: rem(25);
	box-sizing: border-box;
	width: 6px;
	height: 6px;
	border: 6px solid transparent;
	border-left: 6px solid $white;
    transform: translateY(-50%);
    transition: .3s;
    @include mq("md") {
        right: rem(15);
    }
}

.header-nav__btn a:hover::before {
    border-left: 6px solid $gold;
    transition: .3s;
    @include mq("md") {
        border-left: 6px solid $black;
    }
}

.circle{
    position: fixed;
  z-index:3;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba($color: $black, $alpha: .7);
  transform: scale(0);
  right:-50px;
    top:-50px;
    transition: all 2s;
}

.circle.circleactive{
  transform: scale(50);
}
