@use "global" as *;

.p-member {
    padding-top: rem(40);
    padding-bottom: rem(40);
    background-image: url(../src/images/top/member_back_sp.jpg);
    background-repeat: no-repeat;
    background-position: top left;
    // background-size: cover;
    background-size: 100% 100%;

    @include mq("md") {
        background-image: url(../src/images/top/member_back.jpg);
        padding-top: rem(70);
        padding-bottom: rem(70);
    }
}


.p-member__wrap {
    margin-top: rem(30);

    @include mq("md") {
        display: flex;
        column-gap: rem(70);
        max-width: rem(842);
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(50);
    }
}

.p-member__people {
    width: 61%;
    margin-left: auto;
    margin-right: auto;
    min-width: rem(233);

    @include mq("md") {
        width: 39%;
    }
}

.p-member__people img {
    aspect-ratio: 233/216;
    object-fit: cover;

    @include mq("md") {
        aspect-ratio: 323/390;
    }
}

.p-member__name {
    margin-top: rem(10);
    font-size: rem(16);
    font-weight: $medium;
    color: $white;
    text-align: center;
    line-height: 1.5;

    @include mq("md") {
        margin-top: rem(20);
    }
}

.p-member__item {
    margin-top: rem(20);

    @include mq("md") {
        width: 52%;
        margin-top: initial;
    }
}

.p-member__title {
    font-size: rem(20);
    font-weight: $bold;
    line-height: 1.5;
    position: relative;
    z-index: 1;
    padding: rem(2) rem(13);
    max-width: fit-content;

    @include mq("md") {}
}

.p-member__title::before {
    transform: skewX(-18deg);
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: $white;
}

.p-member__text {
    color: $white;
    font-size: rem(16);
    line-height: 1.5;
    margin-top: rem(28);

    @include mq("md") {
        margin-top: rem(15);
    }
}

.p-member__text:first-of-type {
    margin-top: rem(20);

    @include mq("md") {
        margin-top: rem(15);
    }
}

.p-member__btn {
    margin-top: rem(30);
    @include mq("md") {
        margin-top: rem(50);
    }
}