@use "global" as *;

.breadcrumbs {
    margin-top: rem(5);
    @include mq("md") {
     margin-top: rem(10);   
    }
}

.breadcrumb_inner span {
    line-height: 1.43;
    font-size: rem(14);
    color: $black;
    font-weight: $medium;
    display: inline-block;

}