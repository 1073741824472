@use "global" as *;

.l-footer {
    background-color: $black;
    padding-top: rem(45);
    padding-bottom: rem(25);

    @include mq("md") {
        padding-top: rem(70);
    }
}

.l-footer__inner {}



.l-footer__nav {
    @include mq("md") {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: rem(70);
    }
}

.l-footer__link {
    line-height: 1.5;
    color: $white;
    font-family: $second-font-family;
    font-weight: $medium;

}

.l-footer__wrap {
    display: flex;
    column-gap: rem(20);
    justify-content: center;
    margin-top: rem(30);
    flex-wrap: wrap;

    @include mq("md") {
        column-gap: rem(70);
        margin-top: initial;
    }
}

.l-footer__wrap:first-of-type {
    margin-top: initial;
}

.l-footer__sns-wrap {
    display: flex;
    justify-content: center;
    column-gap: rem(80);
    margin-top: rem(30);

    @include mq("md") {
        margin-top: rem(50);
    }
}

.l-footer__icon {
    aspect-ratio: 30/30;
    max-width: rem(30);

}

.l-footer__access {
    margin-top: rem(30);
    font-size: rem(14);
    color: $white;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {
        margin-top: rem(50);
    }
}



.l-footer__copy {
    margin-top: rem(50);
    font-size: rem(12);
    color: $white;
    text-align: center;
    display: block;

    @include mq("md") {
        margin-top: rem(70);
    }
}