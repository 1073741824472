@use "global" as *;

.p-page-blog__wrap {
    @include mq("md") {
        display: flex;
        justify-content: space-between;
        column-gap: rem(120);
        align-items: flex-start;
        margin-top: rem(50);
    }
}

.p-page-blog__body {
    margin-top: rem(30);

    @include mq("md") {
        margin-top: initial;
    }
}

.p-page-blog__list {
    margin-top: rem(30);

    @include mq("md") {
        margin-top: initial;
    }
}

.p-page-blog__pagenavi {
    margin-top: rem(30);
    @include mq("md") {
        margin-top: rem(60);
    }
}

.p-page-blog__sidebar {
    margin-top: rem(50);
    width: 63%;
    margin-left: auto;
    margin-right: auto;
    background-color: #fafafa;

    @include mq("md") {
        margin-top: initial;
        max-width: rem(220);
    }
}

.p-page-blog__cate-title {
    padding: rem(3);
    background-color: $black;
    color: $white;
    text-align: center;
    font-size: rem(18);
    font-weight: $medium;
    line-height: 1.5;
}

.p-page-blog__box {
    padding: rem(20) rem(20) rem(40);
}
.p-page-blog__list {
    margin-top: rem(15);
}

.p-page-blog__list:first-of-type {
    margin-top: initial;
}
.p-page-blog__cate {
    font-size: rem(14);
    font-weight: $medium;
    line-height: 1.43;
    display: block;
    padding-left: rem(25);

    position: relative;
}



.p-page-blog__cate::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: rem(15);
    height: rem(2);
    background-color: $black;
}

.p-page-blog__cate.-active::before {
    display: none;
}