@use "global" as *;


.p-page-contact__wrap {
    margin-top: rem(35);

    @include mq("md") {
        display: flex;
        justify-content: center;
        column-gap: rem(50);
        margin-top: rem(50);
    }
}

.p-page-contact__item {
    margin-top: rem(30);
    position: relative;
    background-color: rgba($color: $black, $alpha: .03);
    padding: rem(25);
    @include mq("md") {
        margin-top: initial;
        width: 33%;
        padding: rem(30) rem(25) rem(25);
    }
}

.p-page-contact__item::before {
    position: absolute;
    inset: 5px -5px -5px 5px;
    border: 1px solid rgba($color: $black, $alpha: .45);
    content: '';
}

.p-page-contact__item:first-of-type {
    margin-top: initial;
}

.p-page-contact__img {
    max-width: rem(30);
    margin-left: auto;
    margin-right: auto;

    @include mq("md") {}
}

.p-page-contact__img img {
    aspect-ratio: 30/30;
    object-fit: cover;
}

.p-page-contact__title {
    text-align: center;
    margin-top: rem(15);
    font-size: rem(20);
    font-weight: $medium;

    @include mq("md") {}
}

.p-page-contact__detail {
    text-align: center;
    margin-top: rem(20);
    font-size: rem(20);
    font-weight: $medium;
}

.p-page-contact__detail span {
    font-family: $second-font-family;
}

.p-page-contact__form {
    margin-top: rem(50);
    @include mq("md") {
        margin-top: rem(110); 
        max-width: rem(700);
        margin-left: auto;
        margin-right: auto;
    }
}